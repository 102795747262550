<template>
    <div>
        <h4 class="text-2xl font-spacegrotesk-medium mb-5">
            Biens à proximité
        </h4>

        <div class="flex flex-col xl:flex-row -mx-2">
            <div
                v-for="(nearbyBien, key) in bien.nearbyProperties"
                :key="key"
                class="w-full xl:w-1/3 mt-6 px-2"
            >
                <bien-list-item
                    :bien="nearbyBien"
                    :hide-show-on-map-link="true"
                    :hide-specs="true"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BienListItem from "@/app/components/biens/BienListItem";
export default {
    name: "BienDetailsNearbyProperties",
    components: { BienListItem },

    props: {
        bien: { type: Object, required: true }
    }
};
</script>

<style scoped></style>
