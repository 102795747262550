<template>
    <button
        class="hamburger hamburger--collapse"
        :class="{ 'is-active': isOpen }"
        type="button"
    >
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
</template>

<script>
export default {
    name: "BurgerButton",

    props: {
        isOpen: { type: Boolean, default: false }
    }
};
</script>

<style scoped lang="scss">
@use "sass:math";

$hamburger-padding-x: 1rem !default;
$hamburger-padding-y: 0rem !default;
$hamburger-layer-width: (math.div(25px, 16px) * 1rem) !default;
$hamburger-layer-height: (math.div(3px, 16px) * 1rem) !default;
$hamburger-layer-spacing: (math.div(6px, 16px) * 1rem) !default;
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: (math.div(1.5px, 16px) * 1rem) !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-active-layer-color: $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter: opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;
$hamburger-types: (collapse);
@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
    display: flex !important;
    align-items: center;
}
</style>
