<template>
    <div
        v-closable="{
            handler: 'closeDropdown'
        }"
        class="dropdown-select"
        :class="{ open: isDropdownOpen }"
    >
        <div class="dropdown-select__field" @click="toggleDropdown">
            <div class="dropdown-select__selected">
                <input
                    class="dropdown-select__input bg-transparent"
                    type="text"
                    :value="selectionSummary"
                    disabled
                />
            </div>

            <ul class="dropdown-select__list">
                <li class="dropdown-select__item">
                    <label class="dropdown-select__label">
                        <input
                            v-model="selectedAlternatives"
                            class="dropdown-select__checkbox"
                            type="checkbox"
                            :name="name"
                            value="*"
                        />

                        <span class="dropdown-select__custom-checkbox">
                            <!-- eslint-disable -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6"><path fill="#404040" fill-rule="nonzero" d="M6.868.187L2.664 4.39 1.132 2.858a.668.668 0 0 0-.944.944l2.004 2.003c.26.26.683.26.944 0L7.812 1.13a.667.667 0 0 0-.944-.944z"/></svg>
                        </span>

                        Tout
                    </label>
                </li>

                <li
                    v-for="alternative in alternatives"
                    :key="alternative.id"
                    class="dropdown-select__item"
                >
                    <label
                        class="dropdown-select__label"
                    >
                        <input
                            v-model="selectedAlternatives"
                            class="dropdown-select__checkbox"
                            type="checkbox"
                            :name="name"
                            :value="alternative.id"
                        />

                        <span class="dropdown-select__custom-checkbox">
                            <!-- eslint-disable -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6"><path fill="#404040" fill-rule="nonzero" d="M6.868.187L2.664 4.39 1.132 2.858a.668.668 0 0 0-.944.944l2.004 2.003c.26.26.683.26.944 0L7.812 1.13a.667.667 0 0 0-.944-.944z"/></svg>
                        </span>

                        {{ alternative.title }}
                    </label>
                </li>
            </ul>
        </div>

        <div
            class="dropdown-select__arrow"
            @click="toggleDropdown"
        >
            <!-- eslint-disable -->
            <svg width="12" height="9" xmlns="http://www.w3.org/2000/svg"><path d="M6.15 9L.3 0H12z" fill="#BFBFBF" fill-rule="evenodd"/></svg>
        </div>
    </div>
</template>

<script>
import { arrayOfObjectsWithIdAndTitleProperties } from "@/app/property-validators";
export default {
    name: "CheckboxesInADropdown",

    props: {
        name: { type: String, required: true },
        value: { type: Array, default: () => [] },
        alternatives: {
            type: Array,
            required: true,
            validator: arrayOfObjectsWithIdAndTitleProperties
        }
    },

    data() {
        return {
            isDropdownOpen: false,
            selectedAlternatives: this.value || []
        };
    },

    computed: {
        selectionSummary() {
            if (this.selectedAlternatives.length === 0) {
                return "Sélectionnez…";
            }

            if (this.selectedAlternatives.includes("*")) {
                return "Tout";
            }

            /* eslint-disable */
            const selectedAlternativeLabels = this.selectedAlternatives.map(altId => {
              const matchingAlternative = this.alternatives.find(alt => parseInt(altId) === alt.id);
              if (!matchingAlternative) {
                  return altId;
              }

              return matchingAlternative.title;
            });
            return selectedAlternativeLabels.sort().join(", ");
            /* eslint-enable */
        }
    },

    watch: {
        selectedAlternatives(value) {
            this.$emit("input", value);
        }
    },

    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },

        closeDropdown() {
            this.isDropdownOpen = false;
        }
    }
};
</script>

<style scoped lang="scss">
.dropdown-select {
    @apply mt-2 flex relative;
    box-sizing: border-box;
    font-size: 1rem;
    line-height: 1.125rem;

    &__field {
        @apply relative;
        flex: 1 1 auto;
    }

    &__selected {
        @apply h-full pb-3 pt-4 px-3;
        box-sizing: border-box;
        border: 0.125rem solid theme("colors.gray.300");
        border-radius: 0.25rem 0 0 0.25rem;

        .open & {
            border-radius: 0.25rem 0 0 0;
        }
    }

    &__input {
        font-size: 0.9375rem;
        line-height: 1.125rem;
        user-select: none;
    }

    &__list {
        @apply absolute w-full flex-col bg-white hidden z-30;
        top: 100%;
        left: 0;
        max-height: 25rem;
        overflow-y: auto;
        border-color: theme("colors.gray.300");
        border-style: solid;
        border-width: 0 0.125rem 0.125rem 0.125rem;
        border-radius: 0 0 0.25rem 0.25rem;
        // box-shadow: 0 2px 10px rgba(#000, 0.3);

        .open & {
            @apply flex;
        }
    }

    &__item {
        box-sizing: border-box;
        border-bottom: 0.125rem solid theme("colors.gray.300");
        color: theme("colors.gray.500");

        &:last-child {
            border: none;
        }
    }

    &__label {
        @apply py-5 px-3 flex;
        cursor: pointer;
        user-select: none;

        &:hover {
            background-color: rgba(#000, 0.03);
        }
    }

    &__checkbox {
        @apply opacity-0 h-0 w-0;

        &:checked ~ .dropdown-select__custom-checkbox {
            svg {
                opacity: 1;
                transition: opacity 0.3s ease-out;
            }
        }
    }

    &__custom-checkbox {
        @apply h-4 w-4 mr-3 inline-flex items-center justify-center;
        border: 0.125rem solid theme("colors.gray.300");
        border-radius: 0.2rem;

        svg {
            opacity: 0;
            transition: opacity 0.3s ease-out;
        }
    }

    &__arrow {
        @apply flex items-center justify-center;
        width: 3.2rem;
        border-color: theme("colors.gray.300");
        border-style: solid;
        border-width: 0.125rem 0.125rem 0.125rem 0;
        border-radius: 0 0.25rem 0.25rem 0;
        cursor: pointer;

        svg {
            transition: transform 0.4s ease-out;
        }

        .open & {
            svg {
                transform: rotate(180deg);
                transition: transform 0.4s ease-out;
            }
        }
    }
}
</style>
