export default {
    props: {
        bien: {
            type: Object,
            required: true
        }
    },

    computed: {
        isForSale() {
            return this.bien.biensTypePrestation !== "L";
        },

        price() {
            const formatter = new Intl.NumberFormat("fr-fr", {
                style: "currency",
                currency: "EUR",
                maximumSignificantDigits: 10
            });

            return formatter.format(
                this.isForSale
                    ? this.bien.biensPrix
                    : parseFloat(this.bien.biensLoyer) +
                          parseFloat(this.bien.biensCharges)
            );
        },

        priceDetails() {
            return this.isForSale
                ? "frais d'agence inclus"
                : "par mois, charges comprises";
        },

        etage() {
            switch (parseInt(this.bien.biensEtage, 10)) {
                case 0:
                    return '<abbr title="Rez-de-chaussée">RDC</abbr>';
                case 1:
                    return "1<sup>er</sup> étage";
                default:
                    return `${this.bien.biensEtage}<sup>e</sup> étage`;
            }
        }
    }
};
