<template>
    <div
        class="carousel-timeline container mx-auto py-16 lg:pt-20 lg-pb-10 px-container lg:px-container-lg"
    >
        <carousel
            v-model="currentSlideIndex"
            :per-page="1"
            :adjustable-height="true"
            :pagination-enabled="false"
            class="lg:px-10"
        >
            <slide v-for="(slide, id) in slides" :key="id">
                <div
                    class="carousel-timeline-slide flex flex-col lg:flex-row items-start px-5"
                >
                    <!-- eslint-disable-next-line -->
                    <div class="carousel-timeline-slide__image w-full lg:mr-20 mb-8 lg:mb-0">
                        <div class="relative aspect-ratio-4/3">
                            <img
                                class="absolute inset-0 w-full h-full object-cover"
                                :src="slide.media.url"
                                :alt="slide.media.alt"
                            />
                        </div>
                    </div>

                    <div class="w-full text-xl text-gray-700">
                        <h3
                            class="mb-2 lg:mb-4 font-spacegrotesk-bold text-4xl lg:text-5xl text-white"
                        >
                            {{ slide.heading }}
                        </h3>
                        <h4
                            class="mb-8 lg:mb-2 font-spacegrotesk-bold text-4xl lg:text-5xl text-gray-700"
                        >
                            {{ slide.year }}
                        </h4>
                        <!-- eslint-disable-next-line -->
                        <div class="wysiwyg" v-html="slide.text"></div>
                    </div>
                </div>
            </slide>
        </carousel>

        <nav class="flex justify-between items-center text-white mt-8 lg:mt-16">
            <button
                v-for="(slide, id) in slides"
                :key="id"
                class="hidden lg:block carousel-timeline__page-indicator text-xl text-white"
                :class="{
                    'font-spacegrotesk-bold': id === currentSlideIndex,
                    'font-spacegrotesk-medium': id !== currentSlideIndex,
                    'opacity-50': id !== currentSlideIndex
                }"
                @click="goToSlide(id)"
                v-text="slide.year"
            />

            <div class="flex justify-between w-full lg:w-auto lg:ml-16">
                <button
                    class="carousel-timeline__pager-button carousel-timeline__pager-button--previous mr-4"
                    :disabled="isCurrentSlideTheFirst"
                    @click="goToPreviousSlide"
                >
                    <img
                        src="@/static/images/pager-button.svg"
                        alt="Précédent"
                    />
                </button>

                <div class="lg:hidden flex items-center">
                    <button
                        v-for="(slide, id) in slides"
                        :key="id"
                        class="rounded-full bg-white w-6px h-6px mx-1"
                        :class="{
                            'opacity-50': id !== currentSlideIndex,
                            'w-8px': id === currentSlideIndex,
                            'h-8px': id === currentSlideIndex
                        }"
                        @click="goToSlide(id)"
                    />
                </div>

                <button
                    class="carousel-timeline__pager-button carousel-timeline__pager-button--next"
                    :disabled="isCurrentSlideTheLast"
                    @click="goToNextSlide"
                >
                    <img src="@/static/images/pager-button.svg" alt="Suivant" />
                </button>
            </div>
        </nav>
    </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
    name: "CarouselTimeline",

    components: { Carousel, Slide },

    props: {
        slides: { type: Array, required: true }
    },

    data() {
        return {
            currentSlideIndex: 0
        };
    },

    computed: {
        isCurrentSlideTheFirst() {
            return this.currentSlideIndex < 1;
        },

        isCurrentSlideTheLast() {
            return this.currentSlideIndex >= this.slides.length - 1;
        }
    },

    methods: {
        goToSlide(slideIndex) {
            if (slideIndex < 0 || slideIndex > this.slides.length - 1) {
                // eslint-disable-next-line
                console.log(`Navigation to slide #${slideIndex} cancelled. Index ${slideIndex} is out of bounds [0, ${this.slides.length - 1}].`);
            }

            this.currentSlideIndex = slideIndex;
        },

        goToPreviousSlide() {
            if (this.isCurrentSlideTheFirst) {
                // eslint-disable-next-line no-console
                console.log("Navigation to previous slide cancelled");
                return;
            }

            this.currentSlideIndex--;
        },

        goToNextSlide() {
            if (this.isCurrentSlideTheLast) {
                // eslint-disable-next-line no-console
                console.log("Navigation to next slide cancelled");
                return;
            }

            this.currentSlideIndex++;
        }
    }
};
</script>

<style scoped lang="scss">
.carousel-timeline {
    &__pager-button {
        &--next {
            transform: rotate(180deg);
        }

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &__page-indicator {
        transition: opacity 250ms ease-in-out;
    }

    .VueCarousel-slide-adjustableHeight::v-deep {
        display: block !important;
    }
    .VueCarousel-inner::v-deep {
        align-items: flex-start !important;
    }
}

.carousel-timeline-slide {
    &__image {
        @screen lg {
            max-width: 35rem;
        }
    }
}
</style>
