<template>
    <div>
        <div class="relative">
            <carousel
                v-model="currentPhotoIndex"
                class="carousel px-4 md:px-6 lg:px-0"
                :per-page="1"
                :pagination-enabled="false"
            >
                <slide
                    v-for="(photo, key) in bien.biensPhotos"
                    :key="key"
                    class="bg-gray-100"
                >
                    <div class="relative aspect-ratio-16/9">
                        <img
                            class="absolute inset-0 w-full h-full object-cover"
                            :src="photo.url"
                        />
                    </div>
                </slide>
            </carousel>

            <div
                class="carousel__pagination absolute lg:flex inset-x-0 bottom-0 px-9 py-3 hidden"
            >
                <div
                    v-for="(photo, key) in bien.biensPhotos"
                    :key="key"
                    class="carousel__pagination-item rounded mx-3"
                    :class="{ 'bg-white': currentPhotoIndex === key }"
                    @click="currentPhotoIndex = key"
                >
                    <img
                        :src="photo.url"
                        class="carousel__pagination-item-img w-full h-full rounded object-cover cursor-pointer"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
    name: "BienDetailsPhotos",
    components: { Carousel, Slide },

    props: {
        bien: { type: Object, required: true }
    },

    data() {
        return {
            currentPhotoIndex: 0
        };
    }
};
</script>

<style scoped lang="scss">
.carousel {
    &__pagination {
        background-image: linear-gradient(
            to top,
            rgba(#191919, 0.6),
            rgba(#191919, 0)
        );
    }

    &__pagination-item {
        width: 5.5rem;
        height: 3.75rem;
        padding: 0.3125rem;
        transition: background-color 0.5s ease;
    }
}
</style>
