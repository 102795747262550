<template>
    <div class="contact-form">
        <form :id="uid('form')" class="mt-4" @submit.prevent="handleFormSubmit">
            <div class="flex flex-col md:flex-row">
                <div class="flex flex-col md:w-2/5">
                    <div class="flex flex-col">
                        <label
                            class="font-spacegrotesk-regular text-base text-gray-700"
                            :for="uid('firstname')"
                            v-text="'Votre prénom'"
                        />
                        <input
                            :id="uid('firstname')"
                            v-model="form.firstName"
                            class="border border-solid border-400 rounded p-2 mt-2"
                            type="text"
                            autocomplete="given-name"
                        />
                        <p
                            v-if="validationErrors.firstName"
                            class="text-base text-red-500 mt-2"
                            v-text="error('firstName')"
                        />
                    </div>

                    <div class="flex flex-col mt-4">
                        <label
                            class="font-spacegrotesk-regular text-base text-gray-700"
                            :for="uid('lastname')"
                            v-text="'Votre nom *'"
                        />
                        <input
                            :id="uid('lastname')"
                            v-model="form.lastName"
                            class="border border-solid border-400 rounded p-2 mt-2"
                            type="text"
                            required
                            autocomplete="family-name"
                        />
                        <p
                            v-if="validationErrors.lastName"
                            class="text-base text-red-500 mt-2"
                            v-text="error('lastName')"
                        />
                        <p
                            v-if="validationErrors.fromName"
                            class="text-base text-red-500 mt-2"
                            v-text="error('fromName')"
                        />
                    </div>

                    <div class="flex flex-col mt-4">
                        <label
                            class="font-spacegrotesk-regular text-base text-gray-700"
                            :for="uid('phone')"
                            v-text="'Votre numéro'"
                        />
                        <input
                            :id="uid('phone')"
                            v-model="form.phone"
                            class="border border-solid border-400 rounded p-2 mt-2"
                            type="tel"
                            autocomplete="tel"
                        />
                    </div>

                    <div class="flex flex-col mt-4">
                        <label
                            class="font-spacegrotesk-regular text-base text-gray-700"
                            :for="uid('email')"
                            v-text="'Votre email *'"
                        />
                        <input
                            :id="uid('email')"
                            v-model="form.email"
                            class="border border-solid border-400 rounded p-2 mt-2"
                            type="text"
                            required
                            autocomplete="email"
                        />
                        <p
                            v-if="validationErrors.fromEmail"
                            class="text-base text-red-500 mt-2"
                            v-text="error('fromEmail')"
                        />
                    </div>
                </div>

                <div class="flex flex-col md:w-3/5 mt-4 md:mt-0 md:ml-8">
                    <label
                        class="font-spacegrotesk-regular text-base text-gray-700"
                        :for="uid('message')"
                        v-text="'Votre message *'"
                    />
                    <textarea
                        :id="uid('message')"
                        v-model="form.message"
                        class="border border-solid border-400 rounded h-32 md:h-full mt-2 p-2"
                        required
                    ></textarea>
                    <p
                        v-if="validationErrors.message"
                        class="text-base text-red-500 mt-2"
                        v-text="error('message')"
                    />
                </div>
            </div>

            <p class="mt-4">* : champ obligatoire</p>
            <p class="text-base text-red-500 mt-4" v-text="error('form')" />
        </form>

        <div
            v-if="!isRecaptchaEnabledInTAC"
            class="tac-recaptcha-engage"
            @click="updateIsRecaptchaEnabledInTAC"
        />

        <div class="md:flex md:justify-end mt-6 md:mt-8">
            <vue-recaptcha
                ref="recaptcha"
                class="z-20"
                :sitekey="recaptchaSiteKey"
                :load-recaptcha-script="false"
                badge="bottomright"
                size="invisible"
                @verify="handleCaptchaVerify"
                @expired="handleCaptchaTimeout"
            />

            <button
                class="btn btn--blue w-full md:w-auto"
                type="submit"
                :form="uid('form')"
                :disabled="isSubmitting || !isRecaptchaEnabledInTAC"
            >
                Envoyer
            </button>
        </div>

        <p v-if="hasBeenSuccessfullySent" class="text-base mt-5">
            Votre message est envoyé. Nous vous répondrons dans les meilleurs
            délais.
        </p>
    </div>
</template>

<script>
import axios from "axios";
import uid from "@/app/mixins/uid";
import recaptchaForm from "@/app/mixins/recaptchaForm";
import "@ungap/url-search-params";
import VueRecaptcha from "vue-recaptcha";

export default {
    name: "ContactForm",
    components: { VueRecaptcha },
    mixins: [uid, recaptchaForm],

    data() {
        return {
            validationErrors: {},
            hasBeenSuccessfullySent: false,
            isSubmitting: false,
            form: {
                email: "",
                firstName: "",
                lastName: "",
                phone: "",
                message: ""
            },
            httpClient: axios.create({
                headers: {
                    "X-Custom-Header": window.Craft.csrfTokenValue
                }
            })
        };
    },

    computed: {
        recaptchaSiteKey() {
            return window._apiKeys.recaptcha;
        }
    },

    methods: {
        error(fieldName) {
            if (!this.validationErrors[fieldName]) {
                return null;
            }

            if (this.validationErrors[fieldName] instanceof Array) {
                return this.validationErrors[fieldName].join(" ");
            }

            return this.validationErrors[fieldName].toString();
        },

        handleFormSubmit() {
            this.isSubmitting = true;
            this.$refs.recaptcha.execute();
        },

        handleCaptchaTimeout() {
            // eslint-disable-next-line no-console
            console.warn("Captcha response expired");
        },

        handleCaptchaVerify(recaptchaResponse) {
            this.isSubmitting = true;
            this.$set(this, "validationErrors", {});

            // Assemble the payload
            const payload = new URLSearchParams();
            payload.append("action", "contact-form/send");
            payload.append("message[firstName]", this.form.firstName);
            payload.append("message[lastName]", this.form.lastName);
            payload.append("fromEmail", this.form.email);
            payload.append("message[formName]", `Formulaire de contact`);
            payload.append("message[Téléphone]", this.form.phone);
            payload.append("message[body]", this.form.message);
            payload.append("g-recaptcha-response", recaptchaResponse);
            payload.append(
                window.Craft.csrfTokenName,
                window.Craft.csrfTokenValue
            );

            // POST the payload
            this.httpClient
                .post(document.location.href, payload, {
                    "Content-Type": "application/x-www-form-urlencoded"
                })
                .then(response => {
                    this.hasBeenSuccessfullySent = true;
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error(error);

                    if (error.response.status === 400) {
                        this.validationErrors = error.response.data.errors
                        return;
                    }

                    this.$set(
                        this.validationErrors,
                        "form",
                        "La messagerie rencontre un problème. Veuillez réessayer ultérieurement. "
                    );
                })
                .finally(() => {
                    this.$refs.recaptcha.reset();
                    this.isSubmitting = false;
                });
        }
    }
};
</script>

<style scoped lang="scss">
.contact-form {
    .tac-recaptcha-engage::v-deep .tac_activate {
        padding: 1rem;
    }
}
</style>
