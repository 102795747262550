<template>
    <div v-if="alternatives.length > 1" class="toggle-switch">
        <div v-for="alternative in alternatives" :key="alternative.id">
            <input
                :id="`radio-button-${alternative.id}`"
                :checked="alternatives.length < 1 || alternative.id === value"
                type="radio"
                :name="name"
                class="toggle-switch__radio-button hidden"
                :value="alternative.id"
                @change="$emit('input', $event.target.value)"
            />

            <label
                :for="`radio-button-${alternative.id}`"
                class="toggle-switch__label inline-block py-3 px-8"
            >
                <!-- eslint-disable vue/no-v-html -->
                <span
                    v-if="alternative.icon"
                    class="toggle-switch__icon"
                    v-html="alternative.icon"
                />
                <!-- eslint-enable vue/no-v-html -->
                {{ alternative.title }}
            </label>
        </div>
    </div>

    <input v-else type="hidden" :name="name" :value="alternatives[0].id" />
</template>

<script>
import { arrayOfObjectsWithIdAndTitleProperties } from "@/app/property-validators";
export default {
    name: "Toggle",

    props: {
        alternatives: {
            type: Array,
            required: true,
            validator: arrayOfObjectsWithIdAndTitleProperties
        },
        // eslint-disable-next-line vue/require-prop-types
        value: { default: null },
        name: { type: String, default: null }
    }
};
</script>

<style lang="scss" scoped>
.toggle-switch {
    $self: &;

    @apply p-1 bg-gray-300 inline-flex flex-row;
    border-radius: 0.25rem;

    &__label {
        @apply flex items-center py-3 px-8 font-spacegrotesk-bold;
        border-radius: 0.25rem;
        color: theme("colors.gray.100");
        font-size: 1.25rem;
        line-height: 1.5rem;
        cursor: pointer;
        transition: all 0.3s ease;

        #{ $self }__radio-button:checked + & {
            @apply bg-white;
            color: theme("colors.secondary");
            transition: all 0.3s ease;

            .toggle-switch__icon svg {
                transition: all 0.3s ease;
                fill: theme("colors.secondary");
            }
        }
    }

    &__icon {
        @apply mr-3;

        svg::v-deep {
            transition: all 0.3s ease;
            fill: currentColor;
        }
    }
}
</style>
