<template>
    <nav class="header-nav relative flex justify-end w-full py-4 md:py-9">
        <ul class="header-nav__menu hidden xl:flex items-center text-white">
            <li
                v-for="(menuItem, key) in menu"
                :key="key"
                class="header-nav__menu-item"
            >
                <a
                    class="header-nav__link text-lg text-white font-spacegrotesk-bold pb-1"
                    :href="menuItem.url"
                    :target="menuItem.target"
                    :class="{
                        'border-b': menuItem.isCurrent,
                        'border-white': menuItem.isCurrent
                    }"
                >
                    {{ menuItem.title }}
                </a>
            </li>
        </ul>

        <div class="flex items-center xl:mx-6">
            <burger-button
                :is-open="dropdownOpened"
                @click.native="toggleDropdownMenu"
            />
        </div>

        <div
            class="header-nav__dropdown"
            :class="{
                'header-nav__dropdown--open': dropdownOpened
            }"
        >
            <div
                class="header-nav__dropdown-header xl:hidden flex justify-between items-start p-4 bg-primary"
            >
                <div class="header-nav__dropdown-connexion flex flex-col">
                    <span
                        class="text-white font-spacegrotesk-bold mb-2"
                        v-text="espaceLocataireCtaTitle"
                    />
                    <a
                        class="text-white underline flex"
                        :href="espaceLocataireCtaUrl"
                        :target="espaceLocataireCtaTarget"
                    >
                        <!-- eslint-disable-next-line -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25"><path fill="#FFF" fill-rule="evenodd" d="M11.02 20.399a3.293 3.293 0 0 0 3.29-3.298 3.293 3.293 0 0 0-3.29-3.297A3.293 3.293 0 0 0 7.733 17.1 3.293 3.293 0 0 0 11.02 20.4zM4.673 9.202V6.365C4.672 2.85 7.515 0 11.021 0a6.35 6.35 0 0 1 6.085 4.547.994.994 0 1 1-1.906.568 4.362 4.362 0 0 0-4.18-3.121 4.365 4.365 0 0 0-4.359 4.371v2.837h12.773a2.528 2.528 0 0 1 2.524 2.531V22.47c0 1.398-1.13 2.531-2.524 2.531H2.607a2.528 2.528 0 0 1-2.524-2.53V11.732c0-1.397 1.13-2.53 2.524-2.53h2.065zm6.349 9.203c-.718 0-1.3-.584-1.3-1.304 0-.72.582-1.303 1.3-1.303.718 0 1.3.583 1.3 1.303s-.582 1.304-1.3 1.304zm8.413 4.601c.296 0 .536-.24.536-.537V11.733a.537.537 0 0 0-.536-.537H2.607a.537.537 0 0 0-.535.537V22.47c0 .296.24.537.535.537h16.827z"/></svg>
                        <span class="ml-3">Me connecter</span>
                    </a>
                </div>
                <button
                    class="header-nav__close"
                    type="button"
                    @click="toggleDropdownMenu"
                >
                    <span class="header-nav__close-box">
                        <span
                            class="header-nav__close-line header-nav__close-line--1"
                        ></span>
                        <span
                            class="header-nav__close-line header-nav__close-line--2"
                        ></span>
                    </span>
                </button>
            </div>
            <ul class="header-nav__dropdown-list">
                <li
                    v-for="(menuItem, key) in menu"
                    :key="key"
                    class="header-nav__dropdown-big-item w-full bg-white xl:hidden"
                >
                    <a
                        class="text-black font-spacegrotesk-bold block py-5 px-4 hover:no-underline hover:bg-gray-100"
                        :href="menuItem.url"
                        :target="menuItem.target"
                    >
                        {{ menuItem.title }}
                    </a>
                </li>
                <li
                    v-for="(dropdownItem, key) in dropdownMenu"
                    :key="key + 100"
                    class="header-nav__dropdown-item xl:border-b"
                >
                    <a
                        class="header-nav__dropdown-link block px-4 py-5 text-black font-spacegrotesk-regular hover:bg-gray-300 xl:hover:bg-gray-100 hover:no-underline"
                        :href="dropdownItem.url"
                        :target="dropdownItem.target"
                    >
                        {{ dropdownItem.title }}
                    </a>
                </li>
            </ul>
        </div>

        <a
            class="header-nav__tenant-space"
            :href="espaceLocataireCtaUrl"
            :target="espaceLocataireCtaTarget"
        >
            <!-- eslint-disable-next-line -->
            <svg class="header-nav__tenant-space-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="22"><path fill-rule="evenodd" d="M22.22 18.455a.994.994 0 0 1-1.376.332 1.016 1.016 0 0 1-.328-1.39A10.106 10.106 0 0 0 22 12.123C22 6.543 17.523 2.02 12 2.02S2 6.544 2 12.123c0 1.88.519 3.687 1.485 5.274.29.476.142 1.098-.328 1.39a.994.994 0 0 1-1.376-.332A12.142 12.142 0 0 1 0 12.123C0 5.428 5.373 0 12 0c6.628 0 12 5.427 12 12.123 0 2.26-.623 4.432-1.78 6.332zM12 15.153c-2.76 0-5-2.262-5-5.05 0-2.79 2.24-5.052 5-5.052s5 2.263 5 5.051c0 2.789-2.24 5.051-5 5.051zm0-2.02c1.656 0 3-1.358 3-3.03 0-1.674-1.344-3.032-3-3.032-1.655 0-3 1.358-3 3.031s1.345 3.03 3 3.03zm7.495 7.348c.278.482.117 1.1-.36 1.381a.994.994 0 0 1-1.368-.363c-.752-1.303-3.078-2.305-5.767-2.305-2.688 0-5.015 1.002-5.767 2.305a.994.994 0 0 1-1.368.363 1.016 1.016 0 0 1-.36-1.381C5.67 18.465 8.667 17.174 12 17.174s6.331 1.29 7.495 3.307z"/></svg>
            <span
                class="hidden xl:inline-block ml-2 text-primary font-spacegrotesk-bold"
                v-text="espaceLocataireCtaTitle"
            />
        </a>
    </nav>
</template>

<script>
import BurgerButton from "@/app/components/BurgerButton";

export default {
    name: "HeaderNav",

    components: { BurgerButton },

    props: {
        menu: {
            type: Array,
            default: null
        },
        dropdownMenu: {
            type: Array,
            default: null
        },
        espaceLocataireCta: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            dropdownOpened: false
        };
    },

    computed: {
        espaceLocataireCtaTitle() {
            if (!this.espaceLocataireCta) {
                return "Espace locataire";
            }

            return this.espaceLocataireCta.title;
        },

        espaceLocataireCtaUrl() {
            if (!this.espaceLocataireCta) {
                return false;
            }

            return this.espaceLocataireCta.url;
        },

        espaceLocataireCtaTarget() {
            if (!this.espaceLocataireCta) {
                return false;
            }

            return this.espaceLocataireCta.target;
        }
    },

    methods: {
        toggleDropdownMenu() {
            this.dropdownOpened = !this.dropdownOpened;

            ["overflow-hidden", "xl:overflow-auto"].forEach(className => {
                document.body.classList.toggle(className, this.dropdownOpened);
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@use "sass:math";

.header-nav {
    &__menu-item {
        @apply ml-9;

        &:first-child {
            @apply ml-0;
        }
    }

    &__link {
        &:hover,
        &:focus,
        &:active {
            @apply border-b-2 border-white no-underline;
        }
    }

    &__close {
        $close-height: 1.6rem;
        $close-width: 1.5rem;
        $line-height: 0.1875rem;
        $line-width: 2rem;

        &-box {
            @apply relative flex flex-col justify-between;
            height: $close-height;
            width: $close-width;
        }

        &-line {
            @apply absolute inline-block w-full bg-white rounded-full;
            top: 50%;
            left: 50%;
            height: $line-height;
            width: $line-width;
            margin-left: math.div($line-width, 2) * -1;
            transform-origin: center;
            &--1 {
                transform: rotate(45deg);
            }
            &--2 {
                transform: rotate(-45deg);
            }
        }
    }

    &__dropdown {
        @apply fixed hidden inset-0 bg-gray-100 h-full w-screen z-30;

        @screen xl {
            @apply absolute inset-auto h-auto bg-white border;
            top: 100%;
            right: -2rem;
            width: 18rem;
            overflow: auto;

            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);
        }

        &--open {
            @apply flex flex-col;
        }
    }

    &__dropdown-list {
        @apply flex-1 overflow-auto;

        @screen xl {
            @apply flex-none;
        }
    }

    &__dropdown-item {
        font-size: 1rem;
    }

    &__dropdown-big-item {
        font-size: 1.125rem;
        border-bottom-width: 1px;
        border-color: theme("colors.gray.200");
    }

    &__tenant-space {
        @apply flex px-3 py-2;

        @screen xl {
            @apply rounded bg-white;
        }
    }

    &__tenant-space-icon {
        fill: theme("colors.white");

        @screen xl {
            fill: theme("colors.primary");
        }
    }
}
</style>
