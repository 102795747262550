let uid = 0;

export default {
    beforeCreate() {
        this.uid = uid.toString();
        uid++;
    },

    methods: {
        uid(name, namespace) {
            namespace = namespace || "lhs";
            return `${namespace}_${uid}_${name}`;
        }
    }
};
