export default {
    data() {
        return {
            isRecaptchaEnabledInTAC: !!window.tarteaucitron.state.recaptcha
        };
    },

    mounted() {
        if (window.tarteaucitron.added.recaptcha) {
            this.populateFallback();
        }


        /* Cet exercice est réalisé par un professionnel du sale,
           ne faites pas ça chez vous les enfants !

           Le but de la manœuvre c'est de n'appeler window.tarteaucitron.engage("recaptcha")
           que quand on est sûr que ça ne balancera pas d'erreur.
           En gros c'est quand Tarteaucitron a appelé sa méthode addService('recaptcha').

           On remplace donc cette méthode par une version encapsulée qui en plus appelle
           window.tarteaucitron.engage("recaptcha") quand elle a été exécutée.
         */
        if (!window.tarteaucitron.added.recaptcha) {
            const addService = window.tarteaucitron.addService;

            window.tarteaucitron.addService = serviceKey => {
                addService(serviceKey);

                if (serviceKey.toLowerCase() === "recaptcha") {
                    // eslint-disable-next-line
                    this.isRecaptchaEnabledInTAC = !!window.tarteaucitron.state.recaptcha;

                    this.populateFallback();
                }
            };
        }
    },

    methods: {
        updateIsRecaptchaEnabledInTAC() {
            setTimeout(() => {
                // eslint-disable-next-line
                this.isRecaptchaEnabledInTAC = window.tarteaucitron.state.recaptcha;
            }, 100);
        },

        populateFallback() {
            window.tarteaucitron.fallback(
                ["tac-recaptcha-engage"],
                window.tarteaucitron.engage("recaptcha")
            );
        }
    }
};
