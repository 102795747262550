<template>
    <div
        class="flex justify-center rounded bg-white shadow-md px-3 lg:px-20 py-8 lg:py-10"
    >
        <form
            v-if="!result"
            class="w-full max-w-xs lg:max-w-full"
            :action="action"
            method="post"
            accept-charset="UTF-8"
            @submit.prevent="onFormSubmit"
        >
            <input
                type="hidden"
                name="action"
                value="calculateur-eligibilite-module/form/submit"
            />

            <div
                class="font-spacegrotesk-medium text-base text-center mb-12 lg:mb-14"
            >
                <p
                    v-if="formError"
                    class="mb-20 text-red-500"
                    v-text="formError"
                />
            </div>

            <div class="flex flex-col lg:flex-row">
                <div
                    class="relative flex flex-col mt-0 mb-8 lg:mt-0 lg:mb-0 lg:w-1/4 lg:mr-8"
                >
                    <img
                        class="absolute bottom-100 self-center"
                        src="@/static/images/picto-un-papa-une-maman.svg"
                        alt=""
                    />
                    <input
                        id="nb-personnes-non-a-charge"
                        v-model="nbPersonnesNonACharge"
                        class="relative w-full border border-solid border-gray-400 p-3 rounded"
                        type="number"
                        min="1"
                        required="required"
                    />
                    <div class="flex items-start mt-2">
                        <label
                            class="font-spacegrotesk-regular text-sm text-gray-700"
                            for="nb-personnes-non-a-charge"
                        >
                            <span>{{ labels.nombreDePersonnes }}</span>
                        </label>
                        <v-popover
                            v-if="tooltips.nombreDePersonnes"
                            class="ml-2 mt-2px flex-shrink-0"
                            trigger="click"
                        >
                            <button type="button">
                                <img
                                    src="@/static/images/tooltip.svg"
                                    alt="En savoir plus"
                                />
                            </button>
                            <template slot="popover">
                                <!-- eslint-disable-next-line -->
                                <div v-html="tooltips.nombreDePersonnes"></div>
                            </template>
                        </v-popover>
                    </div>
                    <!-- eslint-disable -->
                    <div
                        class="font-spacegrotesk-regular text-sm text-red-500"
                        v-html="validationErrors.nbPersonnesNonACharge"
                    />
                    <!-- eslint-enable -->
                </div>

                <div
                    class="relative flex flex-col mt-12 mb-8 lg:mt-0 lg:mb-0 lg:w-1/4 lg:mr-8"
                >
                    <img
                        class="absolute bottom-100 self-center"
                        src="@/static/images/picto-personnes-a-charge.svg"
                        alt=""
                    />
                    <input
                        id="nb-personnes-a-charge"
                        v-model="nbPersonnesACharge"
                        class="relative w-full border border-solid border-gray-400 p-3 rounded"
                        type="number"
                        min="0"
                        required="required"
                    />
                    <div class="flex items-start mt-2">
                        <label
                            class="font-spacegrotesk-regular text-sm text-gray-700"
                            for="nb-personnes-a-charge"
                        >
                            <span>{{ labels.nombreDePersonnesACharge }}</span>
                        </label>
                        <v-popover
                            v-if="tooltips.nombreDePersonnesACharge"
                            class="ml-2 mt-2px flex-shrink-0"
                            trigger="click"
                        >
                            <button type="button">
                                <img
                                    src="@/static/images/tooltip.svg"
                                    alt="En savoir plus"
                                />
                            </button>
                            <template slot="popover">
                                <!-- eslint-disable-next-line -->
                                <div v-html="tooltips.nombreDePersonnesACharge"></div>
                            </template>
                        </v-popover>
                    </div>

                    <!-- eslint-disable -->
                    <div
                        class="font-spacegrotesk-regular text-sm text-red-500"
                        v-html="validationErrors.nbPersonnesACharge"
                    />
                    <!-- eslint-enable -->
                </div>

                <div
                    class="relative flex flex-col mt-12 mb-8 lg:mt-0 lg:mb-0 lg:w-1/4 lg:mr-8"
                >
                    <img
                        class="absolute bottom-100 self-center"
                        src="@/static/images/picto-revenu.svg"
                        alt=""
                    />
                    <input
                        id="revenu-fiscal"
                        v-model="revenuFiscal"
                        class="relative w-full border border-solid border-gray-400 p-3 rounded"
                        type="number"
                        min="0"
                        required="required"
                    />
                    <div class="flex items-start mt-2">
                        <label
                            class="font-spacegrotesk-regular text-sm text-gray-700"
                            for="revenu-fiscal"
                        >
                            <span>{{ labels.revenuFiscal }}</span>
                        </label>
                        <v-popover
                            v-if="tooltips.revenuFiscal"
                            class="ml-2 mt-2px flex-shrink-0"
                            trigger="click"
                        >
                            <button type="button">
                                <img
                                    src="@/static/images/tooltip.svg"
                                    alt="En savoir plus"
                                />
                            </button>
                            <template slot="popover">
                                <!-- eslint-disable-next-line -->
                                <div v-html="tooltips.revenuFiscal"></div>
                            </template>
                        </v-popover>
                    </div>

                    <!-- eslint-disable -->
                    <div
                        class="font-spacegrotesk-regular text-sm text-red-500"
                        v-html="validationErrors.revenuAnnuel"
                    />
                    <!-- eslint-enable -->
                </div>

                <div
                    class="relative lg:w-1/4 flex flex-col"
                    style="flex-shrink: 100"
                >
                    <input
                        class="btn btn--blue w-full"
                        type="submit"
                        value="Valider"
                    />
                </div>
            </div>
        </form>

        <div v-else class="flex flex-col lg:flex-row">
            <!-- prettier-ignore -->
            <div class="lg:w-2/3 lg:mr-20 mb-8 lg:mb-0 text-xl font-spacegrotesk-bold">
                <strong
                    v-if="result.isEligible"
                    class="flex items-center mb-2 font-normal text-4xl text-orange-500"
                >
                    <!-- eslint-disable-next-line -->
                    <svg class="mr-5" width="64" height="64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="none" fill-rule="evenodd"><circle fill="#FF9F4F" cx="24" cy="24" r="24"/><path stroke="#FFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" d="M12 25.2217l10.5 9.3913L36.39 13"/></g></svg>
                    Oui
                </strong>

                <strong
                    v-else
                    class="flex items-center mb-2 font-spacegrostesk-bold text-4xl text-gray-500"
                >
                    <!-- eslint-disable-next-line -->
                    <svg class="mr-5" width="64" height="64" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g fill="none" fill-rule="evenodd"><circle fill="#999" cx="24" cy="24" r="24"/><path d="M24 25.897l-8.8665 8.676a1.4803 1.4803 0 0 1-.0107.0104c-.589.565-1.5246.5454-2.0895-.0437-.5724-.5967-.5573-1.543.0336-2.1213l8.7988-8.6098-8.7988-8.6099c-.591-.5782-.606-1.5246-.0336-2.1213a1.4777 1.4777 0 0 1 .0103-.0107c.5708-.5834 1.5065-.5935 2.0899-.0227L24 21.7201l8.8665-8.676a1.4778 1.4778 0 0 1 .0107-.0104c.589-.565 1.5246-.5454 2.0895.0437.5724.5967.5573 1.543-.0336 2.1213l-8.7988 8.6099 8.7988 8.6098c.591.5782.606 1.5246.0336 2.1213a1.4801 1.4801 0 0 1-.0103.0107c-.5708.5834-1.5065.5935-2.0899.0227L24 25.897z" fill="#FFF"/></g></svg>
                    Non
                </strong>

                <!-- eslint-disable -->
                Parce que votre revenu fiscal de référence de {{ revenuFiscal|currency }}
                {{ result.isEligible ? 'est en dessous du' : 'dépasse le' }} plafond de
                {{ result.maxIncome|currency }}{{ result.isEligible ? ' ou égal à ce dernier' : '' }}, pour un foyer de
                {{ nbPersonnesNonACharge }} {{ nbPersonnesNonACharge > 1 ? 'personnes' : 'personne' }} avec
                {{ nbPersonnesACharge }} {{ nbPersonnesACharge > 1 ? 'personnes' : 'personne' }} à charge.
                <!-- eslint-enable -->
            </div>

            <div class="flex flex-col justify-center items-center lg:w-1/3">
                <template v-if="result.isEligible">
                    <a
                        v-if="anchor"
                        :href="anchor.url"
                        class="btn btn--blue mb-5 lg:mb-6"
                        :target="anchor.target"
                        v-text="anchor.title"
                    />
                    <a
                        href="#"
                        class="font-spacegrotesk-regular text-sm text-gray-700 underline"
                        @click.prevent="resetForm"
                        v-text="'Refaire une simulation'"
                    />
                </template>

                <template v-else>
                    <button
                        class="btn btn--blue lg:mb-6"
                        @click.prevent="resetForm"
                        v-text="'Refaire une simulation'"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { TweenLite, ScrollToPlugin } from "gsap/all";

// eslint-disable-next-line no-unused-vars
const gsapPlugins = [ScrollToPlugin];

export default {
    name: "CalculateurEligibilite",

    filters: {
        currency(value) {
            const options = {
                style: "currency",
                currency: "EUR",
                maximumFractionDigits: 0,
                minimumFractionDigits: 0
            };
            // eslint-disable-next-line
            return new Intl.NumberFormat("fr-FR", options).format(value);
        }
    },

    props: {
        action: { type: String, required: true },
        labels: { type: Object, required: true },
        tooltips: { type: Object, default: () => {} },
        formModel: { type: Object, default: () => {} },
        anchor: { type: Object, default: null }
    },

    data() {
        return {
            nbPersonnesNonACharge: null,
            nbPersonnesACharge: null,
            revenuFiscal: null,
            formError: null,
            result: null,
            validationErrors: {}
        };
    },

    methods: {
        onTooltipButtonFocusChanged(event) {
            event.currentTarget.click();
        },

        async onFormSubmit(event) {
            this.formError = null;
            this.validationErrors = {};

            const formAction = event.target.getAttribute("action");
            try {
                const data = new URLSearchParams({
                    nbPersonnesNonACharge: this.nbPersonnesNonACharge,
                    nbPersonnesACharge: this.nbPersonnesACharge,
                    revenuAnnuel: this.revenuFiscal,
                    [window.Craft.csrfTokenName]: window.Craft.csrfTokenValue
                });

                const { data: response } = await axios.post(formAction, data);

                if (response.error) {
                    this.formError = response.error;

                    if (response.validationErrors) {
                        for (const prop in response.validationErrors) {
                            if (
                                // eslint-disable-next-line
                                response.validationErrors.hasOwnProperty(prop) &&
                                response.validationErrors[prop] instanceof Array
                            ) {
                                // eslint-disable-next-line
                                response.validationErrors[prop] = response.validationErrors[prop].join("<br />");
                            }
                        }
                        this.validationErrors = response.validationErrors;
                    }

                    return;
                }

                this.result = response.result;

                this.scrollToTop();
            } catch (error) {
                // eslint-disable-next-line
                this.formError = "Le calculateur rencontre actuellement un problème. Veuillez ré-essayer ultérieurement.";
                console.error(error);
            }
        },

        resetForm() {
            this.formError = null;
            this.result = null;
            this.validationErrors = {};
            this.nbPersonnesNonACharge = null;
            this.nbPersonnesACharge = null;
            this.revenuFiscal = null;
        },

        scrollToTop() {
            TweenLite.set(window, {
                scrollTo: {
                    y: this.$el,
                    offsetY: 20
                }
            });
        }
    }
};
</script>
