<template>
    <!-- prettier-ignore -->
    <div class="video-with-poster absolute inset-0 flex justify-center items-center">
        <div
            v-if="showPoster"
            class="poster absolute inset-0 z-10 cursor-pointer"
            @click="playVideo()"
        >
            <img
                :src="poster"
                alt="Cliquez pour lire la vidéo"
                class="object-cover w-full h-full"
            />

            <div class="absolute flex justify-center items-center p-8 bottom-0 right-0">
                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64">
                    <g fill="none" fill-rule="evenodd" transform="translate(0 -3)">
                        <circle cx="32" cy="35" r="32" fill="#FFF"/>
                        <path fill="#45B7B7" d="M42 35.5L27 44V27z"/>
                    </g>
                </svg>
            </div>
        </div>
        <slot />
    </div>
</template>

<script>
import Player from "@vimeo/player";

export default {
    name: "VideoWithPoster",

    props: {
        poster: { type: String, required: true }
    },

    data() {
        return {
            showPoster: true
        };
    },

    methods: {
        playVideo() {
            this.showPoster = false;

            const iframe = this.$el.querySelector("iframe");

            if (iframe) {
                const thisComponent = this;
                const player = new Player(iframe);
                player.play();

                player.on("ended", function() {
                    thisComponent.showPoster = true;
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
svg {
    transition: all 200ms ease-in-out;
    border-radius: 50%;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.15);

    circle, path { transition: all 200ms ease-in-out; }
}
.poster:hover svg {
    box-shadow: 0 1px 5px 3px rgba(0, 0, 0, 0.15);
    transform: scale(1.1);

    circle {
        fill: #45B7B7;
    }

    path {
        fill: white;
    }
}
</style>
