var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-vertical flex flex-col lg:flex-row relative py-16 lg:py-0"},[_c('div',{staticClass:"carousel-vertical__background absolute inset-0 bg-gray-100"}),_c('div',{staticClass:"container flex flex-col lg:block mx-auto px-container lg:px-container-lg"},[_c('div',{staticClass:"carousel-vertical__text-container flex flex-col relative lg:w-2/3"},[_c('h3',{staticClass:"font-spacegrotesk-bold text-4xl lg:text5xl text-gray-700 text-center lg:text-left mb-8 lg:mb-5",domProps:{"textContent":_vm._s(_vm.title)}}),_vm._l((_vm.slides),function(slide,id){return (!slide.isDisabled)?_c('a',{key:id,staticClass:"carousel-vertical__pager-link text-xl text-gray-700 mb-4",class:{
                    'font-spacegrotesk-regular': _vm.currentSlideIndex !== id,
                    'font-spacegrotesk-bold': _vm.currentSlideIndex === id,
                    'opacity-50': _vm.currentSlideIndex !== id
                },attrs:{"href":"#"},domProps:{"textContent":_vm._s(slide.heading)},on:{"click":function($event){$event.preventDefault();_vm.currentSlideIndex = id}}}):_vm._e()}),(
                    _vm.currentSlide &&
                        _vm.currentSlide.cta &&
                        _vm.currentSlide.cta.url &&
                        _vm.currentSlide.cta.url.length > 0 &&
                        _vm.currentSlide.cta.title &&
                        _vm.currentSlide.cta.title.length > 0
                )?_c('a',{staticClass:"btn btn--blue mt-8 lg:mt-12",attrs:{"href":_vm.currentSlide.cta.url,"target":_vm.currentSlide.cta.target},domProps:{"textContent":_vm._s(_vm.currentSlide.cta.title)}}):_vm._e()],2),_c('div',{staticClass:"carousel-vertical__image-container order-first lg:absolute mb-8 lg:mb-0"},[_c('div',{staticClass:"relative"},[_c('div',{directives:[{name:"touch",rawName:"v-touch:swipe",value:(_vm.onSwipe),expression:"onSwipe",arg:"swipe"}],ref:"slideContainer",staticClass:"aspect-ratio-4/3 relative overflow-hidden",on:{"mouseover":_vm.stopAutoplay,"mouseleave":_vm.startAutoplay}},[_c('div',{staticClass:"absolute flex lg:flex-col w-full h-full inset-0"},_vm._l((_vm.slides),function(slide,id){return _c('img',{key:id,ref:"slides",refInFor:true,staticClass:"w-full h-full flex-grow flex-shrink-0 object-cover",attrs:{"src":slide.media.url,"alt":slide.media.alt}})}),0)]),_c('div',{staticClass:"lg:hidden absolute inset-x-0 bottom-0 flex justify-center mb-5"},_vm._l((_vm.slides),function(slide,id){return _c('button',{key:id,staticClass:"rounded-full bg-white w-6px h-6px mx-1",class:{
                            'opacity-50': id !== _vm.currentSlideIndex,
                            'w-8px': id === _vm.currentSlideIndex,
                            'h-8px': id === _vm.currentSlideIndex
                        },on:{"click":function($event){_vm.currentSlideIndex = id}}})}),0)]),_c('nav',{staticClass:"absolute hidden lg:flex flex-col ml-10 mb-14 bottom-0"},[_c('button',{staticClass:"carousel-vertical__pager-button carousel-vertical__pager-button--previous mb-5",on:{"click":function($event){_vm.currentSlideIndex--}}},[_c('img',{attrs:{"src":require("@/static/images/pager-button.svg"),"alt":"Précédent"}})]),_c('button',{staticClass:"carousel-vertical__pager-button carousel-vertical__pager-button--next",on:{"click":function($event){_vm.currentSlideIndex++}}},[_c('img',{attrs:{"src":require("@/static/images/pager-button.svg"),"alt":"Suivant"}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }