<template>
    <section class="breves bg-gray-100 py-10 md:py-20">
        <div class="breves__container">
            <h2 class="text-center mb-10">Pluralis en bref</h2>
            <div class="breves__grid container mx-auto px-4 md:px-4">
                <carousel
                    :per-page="1"
                    :per-page-custom="[[768, 2], [992, 3]]"
                    :navigation-enabled="true"
                    :pagination-enabled="false"
                    :navigation-next-label="''"
                    :navigation-prev-label="''"
                >
                    <slide
                        v-for="(breve, key) in breves"
                        :key="key"
                        class="breve px-3"
                    >
                        <div class="w-full relative aspect-ratio-4/3">
                            <img
                                class="absolute inset-0 w-full h-full object-cover"
                                :src="breve.image.url"
                                :alt="breve.image.alt"
                                :width="breve.image.width"
                                :height="breve.image.height"
                            />
                        </div>
                        <h5 class="my-4 text-xl">{{ breve.title }}</h5>

                        <p v-if="breve.date" class="mb-2">
                            <!-- eslint-disable-next-line -->
                            {{ breve.date }}
                        </p>

                        <!-- eslint-disable-next-line -->
                        <div class="breve__text" v-html="breve.description"></div>
                    </slide>
                </carousel>
            </div>
        </div>
    </section>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
    name: "Breves",
    components: { Carousel, Slide },

    props: {
        breves: {
            type: Object,
            default: null
        }
    }
};
</script>

<style lang="scss">
.breve {
    &__text p {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

.VueCarousel-navigation {
    @apply flex justify-between mt-8;

    @screen xl {
        @apply mt-0;
    }

    .VueCarousel-navigation-prev,
    .VueCarousel-navigation-next {
        position: static !important;

        width: 3.75rem;
        height: 3.75rem;

        padding: 0 !important;
        margin: 0 !important;

        border: 0.0625rem solid theme("colors.gray.200") !important;
        border-radius: 50% !important;

        background-color: theme("colors.white") !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: auto !important;

        transform: none !important;

        @screen xl {
            @apply absolute;
            top: 7rem;
        }
    }

    .VueCarousel-navigation-prev {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAATCAYAAAExLsdGAAAABGdBTUEAALGPC/xhBQAAAZtJREFUKBVtUTtPAkEQnl3E+KhoNDEWJipcQgKdxpCQ00IOoi2VifpHfPwQf4DWCkd1YGIBnSREJVqZELXASr1w3o47e+xxRK64mfnmm28eC6A/o9o41D4kKw4Ggek4U8pj9M/U7hZ89FcUMiKpECBVa+yMZ6IUHuexTRIgilIj7TcXDlQw1AACez/gcQbHqnPavt3whN/sD6YXu6WcInOj6hwRuDQL8XYh96GrQylq5PqDd2rasfItTRiz4cBR1Kg0dnkUSNn1CwGiEmKpar2VrNZVU8VUAQLrFk21Hd3vl8rCEukEmojDw0ZSVE6aGlJMpSU1daNwM2KRNgocPZgupeHHmDoRtfJ4ZwLhlDHWCx43mpV+pnY/74rPK0AsIbJvHsP9x4J5PUZO286ah2C7fn9VvudLnMN6xzKftZYiGzVnD3126QmcA8YqM7FEtl3IfmmStrTmq2y3LIHmU9HckrP9P9SQrRbUyghDZZ4oT1TWLcjqmQEhmJmB1bG2w5knni56DQbyGpyXH6z8zURytJth18+FwBO68x/gj7qeziFCVgAAAABJRU5ErkJggg==");
        @screen xl {
            left: -1.2rem;
        }
        @media screen and (min-width: 1460px) {
            left: -6rem;
        }
    }

    .VueCarousel-navigation-next {
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAATCAYAAAExLsdGAAAABGdBTUEAALGPC/xhBQAAAZJJREFUKBVtUj1PAkEQnV3E+FHRYEJlogIJCXRSmJC7mMhBrLGwkH/ix+/QWGutd0d1J4kx0mlCJEQ7NWqBlUo47sadhUWP3DY7b+e9d28mB2mruQnqpE0HIWs5dfUwvjXHmZGlJIiKEco3rpM++stU/52c3VynDr1IGonf+nxXUpQBTAqi0ScDhNPUPMRdXR9KqtITIMO+P3iP81ixbZRa0lix6AOvP+BxxusyJkXwAv+2N5hd6lY3RuTQoEpKd9a82vqPqeYBBGbGco6nG5C23I+M5bZUg1PRrWhJQGDUJByKl7HdEwxwTzKpG3nIU8klUwLhKb1JIjY2JK+QPCo8E14viJjiDA47Ff0gpJgCMmq24Wyjz84QcAEYM+d4onZfLnxNccNz5Wxn1UOwxeArovMUZ2C0Df1RiUJLUI/5xt1iP/g8B8QqA/bNOa89GKXLSLISiVlophuBiyLecyR52pnFcKdT1i9GP+7YSmXu+71RZg5rbUObZJZktQ0vGG8jlihEbmOyZ86OOoa2r/JG3b//qriVOfTVOwAAAABJRU5ErkJggg==");
        @screen xl {
            right: -1.2rem;
        }
        @media screen and (min-width: 1460px) {
            right: -6rem;
        }
    }
}
</style>
