<template>
    <!-- eslint-disable -->
    <button
        class="btn"
        :class="dynamicClassList"
        :disabled="isPending"
    >
        <slot v-if="isIdle" name="idle">Idle</slot>
        <slot v-if="isPending" name="pending">Pending…</slot>
        <slot v-if="isSuccess"name="success">Success</slot>
        <slot v-if="isError"name="error">Error</slot>
    </button>
</template>

<script>
export default {
    name: "MultipleStateButton",

    props: {
        // eslint-disable-next-line
        state: { type: String, default: "idle", validator: (value) =>
                ["success", "error", "pending", "idle"].indexOf(value) !== -1
        }
    },

    computed: {
        isIdle() {
            return this.state === "idle";
        },

        isSuccess() {
            return this.state === "success";
        },

        isError() {
            return this.state === "error";
        },

        isPending() {
            return this.state === "pending";
        },

        dynamicClassList() {
            return {
                "btn--outline-blue": this.isIdle,
                "btn--blue": this.isSuccess || this.isPending,
                "btn--red": this.isError
            };
        }
    }
};
</script>

<style scoped></style>
