<template>
    <!-- eslint-disable-next-line -->
    <div class="carousel-vertical flex flex-col lg:flex-row relative py-16 lg:py-0">
        <!-- eslint-disable-next-line -->
        <div class="carousel-vertical__background absolute inset-0 bg-gray-100"></div>

        <!-- eslint-disable-next-line -->
        <div class="container flex flex-col lg:block mx-auto px-container lg:px-container-lg">
            <!-- eslint-disable-next-line -->
            <div class="carousel-vertical__text-container flex flex-col relative lg:w-2/3">
                <h3
                    class="font-spacegrotesk-bold text-4xl lg:text5xl text-gray-700 text-center lg:text-left mb-8 lg:mb-5"
                    v-text="title"
                />

                <!-- eslint-disable -->
                <a
                    v-for="(slide, id) in slides"
                    v-if="!slide.isDisabled"
                    :key="id"
                    class="carousel-vertical__pager-link text-xl text-gray-700 mb-4"
                    :class="{
                        'font-spacegrotesk-regular': currentSlideIndex !== id,
                        'font-spacegrotesk-bold': currentSlideIndex === id,
                        'opacity-50': currentSlideIndex !== id
                    }"
                    href="#"
                    @click.prevent="currentSlideIndex = id"
                    v-text="slide.heading"
                />
                <!-- eslint-enable -->

                <a
                    v-if="
                        currentSlide &&
                            currentSlide.cta &&
                            currentSlide.cta.url &&
                            currentSlide.cta.url.length > 0 &&
                            currentSlide.cta.title &&
                            currentSlide.cta.title.length > 0
                    "
                    class="btn btn--blue mt-8 lg:mt-12"
                    :href="currentSlide.cta.url"
                    :target="currentSlide.cta.target"
                    v-text="currentSlide.cta.title"
                />
            </div>

            <!-- eslint-disable-next-line -->
            <div class="carousel-vertical__image-container order-first lg:absolute mb-8 lg:mb-0">
                <div class="relative">
                    <div
                        ref="slideContainer"
                        v-touch:swipe="onSwipe"
                        class="aspect-ratio-4/3 relative overflow-hidden"
                        @mouseover="stopAutoplay"
                        @mouseleave="startAutoplay"
                    >
                        <!-- eslint-disable-next-line -->
                        <div class="absolute flex lg:flex-col w-full h-full inset-0">
                            <img
                                v-for="(slide, id) in slides"
                                :key="id"
                                ref="slides"
                                class="w-full h-full flex-grow flex-shrink-0 object-cover"
                                :src="slide.media.url"
                                :alt="slide.media.alt"
                            />
                        </div>
                    </div>

                    <!-- eslint-disable-next-line -->
                    <div class="lg:hidden absolute inset-x-0 bottom-0 flex justify-center mb-5">
                        <button
                            v-for="(slide, id) in slides"
                            :key="id"
                            class="rounded-full bg-white w-6px h-6px mx-1"
                            :class="{
                                'opacity-50': id !== currentSlideIndex,
                                'w-8px': id === currentSlideIndex,
                                'h-8px': id === currentSlideIndex
                            }"
                            @click="currentSlideIndex = id"
                        />
                    </div>
                </div>

                <!-- eslint-disable-next-line -->
                <nav class="absolute hidden lg:flex flex-col ml-10 mb-14 bottom-0">
                    <button
                        class="carousel-vertical__pager-button carousel-vertical__pager-button--previous mb-5"
                        @click="currentSlideIndex--"
                    >
                        <!-- eslint-disable-next-line -->
                        <img src="@/static/images/pager-button.svg" alt="Précédent" />
                    </button>

                    <button
                        class="carousel-vertical__pager-button carousel-vertical__pager-button--next"
                        @click="currentSlideIndex++"
                    >
                        <!-- eslint-disable-next-line -->
                        <img src="@/static/images/pager-button.svg" alt="Suivant" />
                    </button>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { TweenLite, ScrollToPlugin, Power2 } from "gsap/all";

// eslint-disable-next-line
const gsapPlugins = [ScrollToPlugin];

export default {
    name: "CarouselVertical",

    props: {
        title: { type: String, required: true },
        slides: { type: Array, required: true }
    },

    data() {
        return {
            autoplayInterval: null,
            currentSlideIndex: 0
        };
    },

    computed: {
        currentSlide() {
            if (this.slides.length < 1) {
                return null;
            }

            return this.slides[this.currentSlideIndex];
        }
    },

    watch: {
        currentSlideIndex(value) {
            if (value < 0) {
                this.currentSlideIndex = this.slides.length - 1;
            }

            if (value >= this.slides.length) {
                this.currentSlideIndex = 0;
            }

            this.updateSliderPosition();
        }
    },

    mounted() {
        this.startAutoplay();
    },

    methods: {
        startAutoplay() {
            this.stopAutoplay();

            this.autoplayInterval = setInterval(() => {
                this.currentSlideIndex++;
            }, 5000);
        },

        stopAutoplay() {
            if (this.autoplayInterval) {
                clearInterval(this.autoplayInterval);
            }
        },

        onSwipe(direction) {
            const tailwindTheme = require("@/../tailwind.config.theme.js");
            // eslint-disable-next-line
            const isDesktop = window.matchMedia(`(min-width: ${tailwindTheme.screens.lg})`).matches;

            if (
                (isDesktop && !["top", "bottom"].includes(direction)) ||
                (!isDesktop && !["left", "right"].includes(direction))
            ) {
                return;
            }

            // eslint-disable-next-line
            this.currentSlideIndex += ["left", "top"].includes(direction) ? 1 : -1;
        },

        updateSliderPosition() {
            const scrollContainer = this.$refs.slideContainer;
            const destinationSlide = this.$refs.slides[this.currentSlideIndex];

            this.stopAutoplay();
            TweenLite.to(scrollContainer, 0.5, {
                scrollTo: destinationSlide,
                ease: Power2.easeInOut,
                onComplete: () => {
                    this.startAutoplay();
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
@use "sass:math";

.carousel-vertical {
    $lgContainerWidth: 1200px;

    &__background {
        @screen lg {
            right: 37.4%;
        }
    }

    &__text-container {
        @screen lg {
            padding: (math.div(210px, $lgContainerWidth) * 100%)
                (math.div(315px, $lgContainerWidth) * 100%)
                (math.div(145px, $lgContainerWidth) * 100%)
                (math.div(130px, $lgContainerWidth) * 100%);
        }
    }

    &__image-container {
        @screen lg {
            top: (50% - (math.div(28px, 792px) * 100%));
            left: math.div(735px, 1440px) * 100%;
            transform: translateY(-50%);

            width: math.div(705px, 1440px) * 100%;
            max-width: 44rem;
        }
    }

    &__pager-link {
        transition: opacity 250ms ease-in-out;
        &:active,
        &:hover,
        &:focus {
            @apply text-gray-700;
        }

        &:focus {
            @apply underline;
        }
    }

    &__pager-button {
        &--previous {
            transform: rotate(90deg);
        }

        &--next {
            transform: rotate(270deg);
        }
    }
}
</style>
