var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-timeline container mx-auto py-16 lg:pt-20 lg-pb-10 px-container lg:px-container-lg"},[_c('carousel',{staticClass:"lg:px-10",attrs:{"per-page":1,"adjustable-height":true,"pagination-enabled":false},model:{value:(_vm.currentSlideIndex),callback:function ($$v) {_vm.currentSlideIndex=$$v},expression:"currentSlideIndex"}},_vm._l((_vm.slides),function(slide,id){return _c('slide',{key:id},[_c('div',{staticClass:"carousel-timeline-slide flex flex-col lg:flex-row items-start px-5"},[_c('div',{staticClass:"carousel-timeline-slide__image w-full lg:mr-20 mb-8 lg:mb-0"},[_c('div',{staticClass:"relative aspect-ratio-4/3"},[_c('img',{staticClass:"absolute inset-0 w-full h-full object-cover",attrs:{"src":slide.media.url,"alt":slide.media.alt}})])]),_c('div',{staticClass:"w-full text-xl text-gray-700"},[_c('h3',{staticClass:"mb-2 lg:mb-4 font-spacegrotesk-bold text-4xl lg:text-5xl text-white"},[_vm._v("\n                        "+_vm._s(slide.heading)+"\n                    ")]),_c('h4',{staticClass:"mb-8 lg:mb-2 font-spacegrotesk-bold text-4xl lg:text-5xl text-gray-700"},[_vm._v("\n                        "+_vm._s(slide.year)+"\n                    ")]),_c('div',{staticClass:"wysiwyg",domProps:{"innerHTML":_vm._s(slide.text)}})])])])}),1),_c('nav',{staticClass:"flex justify-between items-center text-white mt-8 lg:mt-16"},[_vm._l((_vm.slides),function(slide,id){return _c('button',{key:id,staticClass:"hidden lg:block carousel-timeline__page-indicator text-xl text-white",class:{
                'font-spacegrotesk-bold': id === _vm.currentSlideIndex,
                'font-spacegrotesk-medium': id !== _vm.currentSlideIndex,
                'opacity-50': id !== _vm.currentSlideIndex
            },domProps:{"textContent":_vm._s(slide.year)},on:{"click":function($event){return _vm.goToSlide(id)}}})}),_c('div',{staticClass:"flex justify-between w-full lg:w-auto lg:ml-16"},[_c('button',{staticClass:"carousel-timeline__pager-button carousel-timeline__pager-button--previous mr-4",attrs:{"disabled":_vm.isCurrentSlideTheFirst},on:{"click":_vm.goToPreviousSlide}},[_c('img',{attrs:{"src":require("@/static/images/pager-button.svg"),"alt":"Précédent"}})]),_c('div',{staticClass:"lg:hidden flex items-center"},_vm._l((_vm.slides),function(slide,id){return _c('button',{key:id,staticClass:"rounded-full bg-white w-6px h-6px mx-1",class:{
                        'opacity-50': id !== _vm.currentSlideIndex,
                        'w-8px': id === _vm.currentSlideIndex,
                        'h-8px': id === _vm.currentSlideIndex
                    },on:{"click":function($event){return _vm.goToSlide(id)}}})}),0),_c('button',{staticClass:"carousel-timeline__pager-button carousel-timeline__pager-button--next",attrs:{"disabled":_vm.isCurrentSlideTheLast},on:{"click":_vm.goToNextSlide}},[_c('img',{attrs:{"src":require("@/static/images/pager-button.svg"),"alt":"Suivant"}})])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }