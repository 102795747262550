<template>
    <article class="bien-map-info-window">
        <img
            class="bien-map-info-window__photo object-cover"
            :src="firstPhotoUrl"
            :alt="bien.heading"
        />
        <div class="pt-4 pb-8 px-5">
            <div class="text-lg text-gray-700">
                {{ bien.heading }}
            </div>
            <div class="text-lg text-gray-500 mt-1">
                {{ bien.biensVille }} ({{ bien.biensCodePostal }})
            </div>
            <div class="text-center mt-3">
                <p class="text-4xl font-spacegrotesk-bold">{{ price }}</p>
                <p class="text-gray-500 text-xs">
                    {{ priceDetails }}
                </p>
                <!-- eslint-disable -->
                <a
                    :href="bien.url"
                    class="btn btn--outline-blue text-base mt-4"
                    @click.prevent="openBienDetails"
                >
                    Découvrir le bien
                </a>
                <!-- eslint-enable -->
            </div>
        </div>
    </article>
</template>

<script>
import BienItemMixin from "@/app/mixins/bienItem";

export default {
    name: "BienMapInfoWindow",

    mixins: [BienItemMixin],

    computed: {
        firstPhotoUrl() {
            if (!this.bien.biensPhotos || this.bien.biensPhotos.length < 1) {
                return null;
            }

            return this.bien.biensPhotos[0].url;
        }
    },

    methods: {
        openBienDetails(event) {
            this.$store.commit("biens/setDisplayMode", "bien-list");

            this.$nextTick(() => {
                this.$store.dispatch("biens/openSideShutter", {
                    bienSlug: this.bien.slug,
                    event
                });
            });
        }
    }
};
</script>

<style scoped lang="scss">
.bien-map-info-window {
    &__photo {
        width: 100%;
        height: 160px;
    }
}
</style>

<style lang="scss">
.gm-style .gm-style-iw {
    min-width: 280px !important;

    padding: 0 !important;

    .gm-style-iw-d {
        overflow: auto !important;
        max-width: none !important;
    }

    & > button {
        width: 2rem !important;
        height: 2rem !important;
        top: 0 !important;
        right: 0 !important;

        //noinspection CssReplaceWithShorthandSafely
        background: center no-repeat theme("colors.secondary") !important;
        //noinspection CssReplaceWithShorthandSafely
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="%23FFF" fill-rule="evenodd" d="M8,9.26466061 L2.08898629,15.0487206 C2.08663146,15.0510249 2.08426509,15.0533173 2.08188727,15.0555978 C1.68917374,15.4322396 1.06548805,15.4192108 0.688846298,15.0264973 C0.307294261,14.628664 0.317308041,13.997764 0.711293693,13.6122402 L6.57714891,7.87236877 L0.711293693,2.13249734 C0.317308041,1.74697351 0.307294261,1.11607356 0.688846298,0.718240223 C0.69112681,0.715862398 0.693419235,0.713496027 0.695723487,0.7111412 C1.07628592,0.32222584 1.70007093,0.315454459 2.08898629,0.696016897 L8,6.48007693 L13.9110137,0.696016897 C13.9133685,0.693712646 13.9157349,0.69142022 13.9181127,0.689139708 C14.3108263,0.312497952 14.9345119,0.325526696 15.3111537,0.718240223 C15.6927057,1.11607356 15.682692,1.74697351 15.2887063,2.13249734 L9.42285109,7.87236877 L15.2887063,13.6122402 C15.682692,13.997764 15.6927057,14.628664 15.3111537,15.0264973 C15.3088732,15.0288751 15.3065808,15.0312415 15.3042765,15.0335963 C14.9237141,15.4225117 14.2999291,15.4292831 13.9110137,15.0487206 L8,9.26466061 Z"/></svg>') !important;
        opacity: 1 !important;

        & > img {
            display: none !important;
        }
    }
}
</style>
