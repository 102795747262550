<template>
    <section
        class="search-engine flex flex-col justify-center"
        :class="{
            'bg-primary': prestationTypes.length === 1
        }"
    >
        <div
            class="search-engine__container container mx-auto px-container lg:px-container-lg"
        >
            <div
                class="search-engine__body pt-14 pb-8 md:py-20 flex flex-col flex-center items-center"
            >
                <h3
                    class="w-full mb-10 px-4 text-center xl:text-left"
                    :class="{
                        'xl:text-center': prestationTypes.length > 1,
                        'font-spacegrotesk-bold': prestationTypes.length === 1,
                        'text-white': prestationTypes.length === 1
                    }"
                    v-text="heading"
                />

                <form
                    method="post"
                    accept-charset="UTF-8"
                    class="flex flex-col items-center w-full md:w-auto"
                    :action="formAction"
                >
                    <input type="hidden" :name="csrfTokenName" :value="csrfTokenValue" />

                    <toggle
                        v-model="selectedPrestationType"
                        class="mb-6"
                        :alternatives="prestationTypes"
                        name="prestationType"
                    />

                    <div
                        class="search-engine__form w-full xl:w-auto px-4 py-6 md:px-7 md:py-8 flex flex-col xl:flex-row items-end bg-white"
                    >
                        <label class="search-engine__label">
                            Ville ou code postal
                            <input
                                v-model="searchModel.location"
                                type="search"
                                name="location"
                                class="search-engine__search"
                                placeholder="Ex. Grenoble"
                            />
                        </label>
                        <div
                            class="search-engine__dot hidden xl:inline-block"
                        ></div>

                        <label class="search-engine__label">
                            Type de bien

                            <checkboxes-in-a-dropdown
                                v-model="selectedBienType"
                                name="bienTypes[]"
                                :alternatives="bienTypes"
                            />
                        </label>

                        <div
                            class="search-engine__dot hidden xl:inline-block"
                        ></div>

                        <label class="search-engine__label">
                            Nombre de chambres

                            <checkboxes-in-a-dropdown
                                v-model="selectedRoomCount"
                                name="roomCounts[]"
                                :alternatives="roomCounts"
                            />
                        </label>

                        <button class="btn btn--blue" type="submit">
                            Valider
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import Toggle from "@/app/components/search-form/Toggle";
import CheckboxesInADropdown from "@/app/components/search-form/CheckboxesInADropdown";

export default {
    name: "SearchEngine",

    components: { Toggle, CheckboxesInADropdown },

    props: {
        heading: { type: String, default: null },
        csrfParam: { type: String, default: "CRAFT_CSRF_TOKEN" },
        csrfToken: { type: String, default: "" },
        searchModel: { type: Object, default: null },
        bienTypes: { type: Array, default: null },
        prestationTypes: { type: Array, default: null },
        maximumRoomCount: { type: Number, default: null }
    },

    data() {
        return {
            selectedPrestationType: this.getDefaultPrestationType(),
            selectedBienType: this.searchModel.bienTypes || [],
            selectedRoomCount: this.searchModel.roomCounts || []
        };
    },

    computed: {
        csrfTokenName() {
            return window.Craft.csrfTokenName;
        },
        csrfTokenValue() {
            return window.Craft.csrfTokenValue;
        },
        roomCounts() {
            // Génère un tableau de la forme [ {id: 1, title: 1}, ... , {id: this.maximumRoomCount, title: this.maximumRoomCount}]
            return [...Array(this.maximumRoomCount).keys()].map(i => {
                i++;
                return { id: i, title: i };
            });
        },

        formAction() {
            const selectedPrestationType = this.prestationTypes.find(
                item => item.id === this.selectedPrestationType
            );

            if (!selectedPrestationType || !selectedPrestationType.formAction) {
                return false;
            }

            return selectedPrestationType.formAction + "#resultats";
        }
    },

    methods: {
        getDefaultPrestationType() {
            if (this.prestationTypes.length < 1) {
                return null;
            }

            this.prestationTypes.forEach(prestationType => {
                if (prestationType.typesDePrestationsDefaultType) {
                    return prestationType.id;
                }
            });

            return this.prestationTypes[0].id;
        }
    }
};
</script>

<style lang="scss">
.search-engine {
    &__form {
        border-radius: 0.25rem;

        @screen md {
            box-shadow: 0 2px 10px rgba(#000, 0.3);
        }
    }

    &__search {
        @apply pb-3 pt-4 mt-2 px-3;
        border: 0.125rem solid theme("colors.gray.300");
        border-radius: 0.25rem;
        font-size: 0.9375rem;
        line-height: 1.125rem;

        @screen md {
            min-width: 18.75rem;
        }
    }

    &__select {
        @apply pl-3;
    }

    &__bien-types {
        min-width: 18.75rem;
    }

    &__label {
        @apply flex flex-col w-full mb-6 font-spacegrotesk-regular;

        &:first-child {
            @apply ml-0;
        }

        @screen xl {
            @apply mx-6 mb-0 w-auto;
        }
    }

    &__dot {
        @apply bg-secondary mb-6;
        height: 0.5rem;
        width: 0.5rem;
        border-radius: 100%;
    }
}
</style>
