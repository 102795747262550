<template>
    <div>
        <a href="#" @click.prevent.stop="isPanelOpen = !isPanelOpen">
            <slot name="link" :isOpened="isPanelOpen">Afficher / masquer</slot>
        </a>

        <transition :duration="1000" @enter="openPanel" @leave="closePanel">
            <div
                v-show="isPanelOpen"
                ref="panelContainer"
                class="overflow-hidden"
            >
                <div ref="panel" class="pt-6">
                    <slot name="content" />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { TweenLite, CSSPlugin, Power3 } from "gsap/all";

// eslint-disable-next-line no-unused-vars
const gsapPlugins = [CSSPlugin];

export default {
    name: "Papillon",

    data() {
        return {
            isPanelOpen: false
        };
    },

    methods: {
        openPanel() {
            this.animatePanelHeight(0, this.$refs.panel.clientHeight);
        },

        closePanel() {
            this.animatePanelHeight(this.$refs.panel.clientHeight, 0);
        },

        animatePanelHeight(from, to) {
            TweenLite.fromTo(
                this.$refs.panelContainer,
                1,
                { height: from },
                { height: to, ease: Power3.easeInOut }
            );
        }
    }
};
</script>
