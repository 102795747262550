<template>
    <gmap-map
        ref="map"
        :center="{ lng: 5.724524, lat: 45.188529 }"
        :zoom="10"
        :options="{
            mapTypeControl: false
        }"
        map-type-id="roadmap"
        class="bien-map"
    >
        <gmap-info-window
            :options="{
                maxWidth: 320,
                pixelOffset: { width: 0, height: -70 }
            }"
            :position="getLatLng(selectedBien)"
            :opened="selectedBien !== null"
            @closeclick="toggleSelected(selectedBien)"
        >
            <bien-map-info-window v-if="selectedBien" :bien="selectedBien" />
        </gmap-info-window>

        <gmap-marker
            v-for="bien in resultsWithGpsCoordinates"
            :key="bien.id"
            ref="markers"
            :position="getLatLng(bien)"
            :clickable="true"
            :draggable="false"
            :icon="{
                url: getMarkerImageUrl(bien),
                scaledSize: { width: 66, height: 83 },
                anchor: { x: 33, y: 72 }
            }"
            :z-index="getZindex(bien)"
            @click="toggleSelected(bien)"
        />
    </gmap-map>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import GmapMap from "vue2-google-maps/dist/components/map";
import GmapMarker from "vue2-google-maps/dist/components/marker";
import GmapInfoWindow from "vue2-google-maps/dist/components/infoWindow";
import BienMapInfoWindow from "@/app/components/biens/BienMapInfoWindow";

export default {
    name: "BienMap",

    components: { GmapMap, GmapMarker, GmapInfoWindow, BienMapInfoWindow },

    props: {
        results: { type: Array, default: null }
    },

    data() {
        return {};
    },

    computed: {
        google() {
            return gmapApi;
        },

        selectedBien() {
            return this.$store.state.biens.selectedBien;
        },

        map() {
            return this.$refs.map;
        },

        resultsWithGpsCoordinates() {
            return this.results.filter(result => {
                return (
                    result.biensGpsCoordinates.lat &&
                    result.biensGpsCoordinates.lng
                );
            });
        }
    },

    mounted() {
        this.map.$mapPromise.then(this.fitBounds);
    },

    methods: {
        isBienSelected(bien) {
            return this.selectedBien && this.selectedBien.id === bien.id;
        },

        getLatLng(bien) {
            if (!bien) {
                return null;
            }

            return {
                lat: parseFloat(bien.biensGpsCoordinates.lat),
                lng: parseFloat(bien.biensGpsCoordinates.lng)
            };
        },

        getMarkerByBienId: function(bienId) {
            return this.$refs.markers.find(
                marker => marker.$attrs["bien-id"] === bienId
            );
        },

        getMarkerImageUrl(bien) {
            if (this.isBienSelected(bien)) {
                return require("@/static/images/map-marker-selected.png");
            }

            return require("@/static/images/map-marker.png");
        },

        getZindex(bien) {
            return this.isBienSelected(bien) ? 9999999 : undefined;
        },

        fitBounds() {
            // eslint-disable-next-line no-undef
            const bounds = new google.maps.LatLngBounds();
            for (const bien of this.resultsWithGpsCoordinates) {
                bounds.extend(this.getLatLng(bien));
            }

            this.map.fitBounds(bounds);
        },

        toggleSelected(bien) {
            if (this.selectedBien && this.selectedBien.id === bien.id) {
                this.$store.commit("biens/unselect");
                return;
            }

            this.$store.commit("biens/setSelected", bien);
        }
    }
};
</script>
