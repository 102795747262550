<template>
    <!-- prettier-ignore -->
    <div class="video-autoplay absolute inset-0 flex justify-center items-center">
        <slot />
    </div>
</template>

<script>
import Player from "@vimeo/player";

export default {
    name: "VideoAutoplay",

    data() {
        return {
            player: null
        };
    },

    mounted() {
        const iframe = this.$el.querySelector("iframe");

        if (iframe) {
            this.init(iframe);
        } else {
            document.addEventListener("vimeo_loaded", () => {
                this.init(this.$el.querySelector("iframe"));
            });
        }
    },

    methods: {
        init(iframe) {
            if (iframe) {
                this.player = new Player(iframe);
                this.player.play();
            }
        },

        play() {
            if (!this.player) {
                console.log("player is undefined: aborting…");
                return;
            }

            this.player.play();
        },

        stop() {
            if (!this.player) {
                console.log("player is undefined: aborting…");
                return;
            }

            this.player.pause();
        }
    }
};
</script>
