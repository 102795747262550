// prettier-ignore
module.exports = {
    colors: {
        transparent: 'transparent',

        black: '#000',
        white: '#fff',

        get primary () {
            return this.green[ '500' ];
        },

        get secondary () {
            return this.teal[ '500' ];
        },

        get third () {
            return this.orange[ '500' ];
        },

        gray:     {
            100: '#f2f2f2',
            200: '#eaeaea',
            300: '#d4d4d4',
            400: '#bfbfbf',
            500: '#999999',
            600: '#666666',
            700: '#4d4d4d',
            800: '#333333',
            900: '#1a1a1a',
        },
        orange:   {
            500: '#FF9F4F',
        },
        green:    {
            500: '#A0BD66',
        },
        teal:     {
            500: '#45B7B7',
            600: '#3ea5a5',
            700: '#379292',
        },
        blue:     {
            300: '#86A7CC',
            400: '#6493C8',
            500: '#4299e1',
        },
        purple:   {
            500: '#8B4C8C',
        },
        red:      {
            500: '#f00',
            600: '#b00',
            700: '#800',
        },
        dpeConso: {
            A: '#009437',
            B: '#5CB000',
            C: '#C9D400',
            D: '#FFED00',
            E: '#FBB700',
            F: '#F16000',
            G: '#EA0000',
        },
        dpeGES:   {
            A: '#F6ECF4',
            B: '#D8BFDC',
            C: '#C7A9CD',
            D: '#B995C1',
            E: '#9E75AD',
            F: '#81569B',
            G: '#6C4290',
        },
    },

    spacing: {
        '-48': '-12rem',
        px:    '1px',
        '2px': '1px',
        '0':   '0',
        '1':   '0.25rem',
        '2':   '0.5rem',
        '3':   '0.75rem',
        '4':   '1rem',
        '5':   '1.25rem',
        '6':   '1.5rem',
        '7':   '1.75rem',
        '8':   '2rem',
        '9':   '2.25rem',
        '10':  '2.5rem',
        '11':  '2.75rem',
        '12':  '3rem',
        '14':  '3.5rem',
        '15':  '3.75rem',
        '16':  '4rem',
        '19':  '4.75rem',
        '20':  '5rem',
        '24':  '6rem',
        '28':  '7rem',
        '30':  '7.5rem',
        '32':  '8rem',
        '34':  '8.5rem',
        '36':  '9rem',
        '40':  '10rem',
        '42':  '10.5rem',
        '44':  '11rem',
        '48':  '12rem',
        '55':  '13.75rem',
        '56':  '14rem',
        '64':  '16rem',
    },

    fontSize: {
        'xs':   '.75rem',
        'sm':   '.875rem',
        'base': '1rem',
        'lg':   '1.125rem',
        'xl':   '1.25rem',
        '2xl':  '1.5rem',
        '3xl':  '1.875rem',
        '4xl':  '2.25rem',
        '5xl':  '2.5rem',
        '6xl':  '3.125rem',
        '8xl':  '6.25rem',
        '10xl': '10rem',
    },

    lineHeight: {
        'extra-tight': 0.62,
        'none':        1,
        'tight':       1.25,
        'snug':        1.375,
        'normal':      1.5,
        'relaxed':     1.625,
        'loose':       2,
    },

    maxWidth: {
        '2xs':  '18rem',
        'xs':   '20rem',
        'sm':   '24rem',
        'md':   '28rem',
        'lg':   '32rem',
        'xl':   '36rem',
        '2xl':  '42rem',
        '3xl':  '48rem',
        '4xl':  '56rem',
        '5xl':  '64rem',
        '6xl':  '72rem',
        'full': '100%',
    },

    flexGrow: {
        '0':     0,
        default: 1,
        '1':     1,
        '2':     2,
    },

    extend:  {
        inset:     {
            100: '100%',
        },
        spacing:   {
            'container':    '1rem',
            'container-lg': '1.875rem',
            'grid':         '0.5rem',
            'grid-lg':      '0.9375rem',
        },
        width:     {
            '6px':   '0.375rem',
            '8px':   '0.5rem',
            '8/12':  (() => (100 / 12) * 8 + '%')(),
            '10/12': (() => (100 / 12) * 10 + '%')(),
            '1/12':  (() => 100 / 12 + '%')(),
        },
        height:    {
            '1/2': '50%',
            '6px': '0.375rem',
            '8px': '0.5rem',
            '108': '27rem',
            '64':  '16rem',
        },
        minHeight: {
            'hero':  '34rem',
            '192px': '12rem',
            '220px': '13.75rem',
            '300px': '18.75rem',
            '480px': '30rem',
            '500px': '31.25rem',
            '600px': '37.5rem',
            '628px': '39.25rem',
            '90vh':  '90vh',
        },
        opacity:   {
            60: '.6',
        },
    },
    screens: {
        md: '768px',
        lg: '992px',
        xl: '1200px',
    },

    fontFamily: {
        'spacegrotesk-bold':    [ 'spacegroteskBold', 'sans-serif' ],
        'spacegrotesk-medium':  [ 'spacegroteskMedium', 'sans-serif' ],
        'spacegrotesk-light':   [ 'spacegroteskLight', 'sans-serif' ],
        'spacegrotesk-regular': [ 'spacegroteskRegular', 'sans-serif' ],
    },

    aspectRatio: {
        'square': [ 1, 1 ],
        '16/9':   [ 16, 9 ],
        '4/3':    [ 4, 3 ],
        '11/5':   [ 11, 5 ],
    },

    columnCount: [ 1, 2 ],
    columnGap: {
        md: '2.5rem',
        lg: '5rem',
    },
};
