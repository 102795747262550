import { render, staticRenderFns } from "./BienDpe.vue?vue&type=template&id=199f1763&scoped=true"
import script from "./BienDpe.vue?vue&type=script&lang=js"
export * from "./BienDpe.vue?vue&type=script&lang=js"
import style0 from "./BienDpe.vue?vue&type=style&index=0&id=199f1763&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "199f1763",
  null
  
)

export default component.exports