<template>
    <div class="w-full bg-primary pt-16 pb-40 px-4 md:px-6 text-white">
        <h3
            class="font-spacegrotesk-medium text-2xl text-white"
            v-text="bien.heading"
        />

        <div class="text-3xl font-spacegrotesk-bold">
            {{ price }}
            <span
                class="text-base font-spacegrotesk-regular mt-1"
                v-text="priceDetails"
            />
        </div>
    </div>
</template>

<script>
import BienItem from "@/app/mixins/bienItem";
export default {
    name: "BienDetailsMobileHeader",
    mixins: [BienItem]
};
</script>
