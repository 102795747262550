import Vue from "vue";
import Vuex from "vuex";
import biens from "./modules/biens";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
    modules: {
        biens
    },
    strict: debug
});
