import "./assets/styles/main.scss";

import Vue from "vue";
import VueClosable from "vue-closable";
import { Slide } from "vue-carousel";
import VideoWithPoster from "@/app/components/VideoWithPoster";
import VideoAutoplay from "@/app/components/VideoAutoplay";
import SearchEngine from "@/app/components/SearchEngine";
import Breves from "@/app/components/Breves";
import HeaderNav from "@/app/components/HeaderNav";
import BienResults from "@/app/components/biens/BienResults";
import BienDetails from "@/app/components/biens/BienDetails";
import BienMap from "@/app/components/biens/BienMap";
import SideShutter from "@/app/components/SideShutter";
import CalculateurEligibilite from "@/app/components/CalculateurEligibilite";
import CarouselTimeline from "@/app/components/CarouselTimeline";
import CarouselVertical from "@/app/components/CarouselVertical";
import ContactForm from "@/app/components/ContactForm";
import Papillon            from "@/app/components/Papillon";
import * as VueGoogleMaps  from "vue2-google-maps";
import store               from "@/app/store";
import objectFitPolyfill   from "object-fit-images";
import VTooltip            from "v-tooltip";
import Vue2TouchEvents     from "vue2-touch-events";
import VueClipboard        from "vue-clipboard2";
import VueFilterDateFormat from "vue-filter-date-format";
import VueFilterDateParse  from "@vuejs-community/vue-filter-date-parse";
import "url-search-params-polyfill";
import ImageMosaic         from '@/app/components/ImageMosaic';

Vue.use(Vue2TouchEvents);
Vue.use(VueClosable); // Directive pour détecter les clics hors d'un élément
Vue.use(VueGoogleMaps, {
    load: {
        key: window._apiKeys.googleMaps,
        region: "FR"
        // language: "fr"
    },
    installComponents: false
});
Vue.use(VueClipboard);
Vue.use(VTooltip, {
    defaultTrigger: "focus",
    defaultPlacement: "bottom",
    defaultDelay: {
        show: 250,
        hide: 250
    }
});
Vue.use(VueFilterDateParse);
Vue.use(VueFilterDateFormat, {
    dayOfWeekNames: [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi"
    ],
    dayOfWeekNamesShort: ["Di", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    monthNames: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre"
    ],
    monthNamesShort: [
        "J",
        "M",
        "M",
        "A",
        "M",
        "J",
        "J",
        "A",
        "S",
        "O",
        "N",
        "D"
    ]
});

new Vue({
    el: "#app",
    components: {
        SearchEngine,
        Breves,
        HeaderNav,
        BienMap,
        BienDetails,
        BienResults,
        SideShutter,
        CalculateurEligibilite,
        Papillon,
        CarouselTimeline,
        Slide,
        CarouselVertical,
        ContactForm,
        VideoWithPoster,
        VideoAutoplay,
        ImageMosaic
    },
    store,

    mounted() {
        objectFitPolyfill();
    }
});
