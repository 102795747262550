<template>
    <div class="side-shutter fixed inset-0 w-screen h-screen z-50">
        <div
            class="absolute h-screen w-full lg:w-7/12 right-0 inset-y-0 bg-white"
        >
            <button
                class="side-shutter__close-button absolute top-0 right-0 lg:left-0 lg:right-auto bg-primary lg:bg-secondary z-30"
                type="button"
                @click="$store.dispatch('biens/closeSideShutter')"
            >
                <!-- eslint-disable-next-line -->
                <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg"><path d="M25 26.976l-9.236 9.0376a1.5394 1.5394 0 0 1-.011.0108c-.6137.5885-1.5882.5681-2.1767-.0455-.5962-.6216-.5805-1.6074.0351-2.2098l9.1654-8.9685-9.1654-8.9686c-.6156-.6024-.6313-1.5881-.035-2.2097a1.5396 1.5396 0 0 1 .0107-.0111c.5946-.6077 1.5693-.6183 2.177-.0237L25 22.6251l9.236-9.0376a1.5402 1.5402 0 0 1 .011-.0107c.6137-.5885 1.5882-.5682 2.1767.0455.5962.6216.5805 1.6073-.0351 2.2097l-9.1654 8.9686 9.1654 8.9685c.6156.6024.6313 1.5882.035 2.2098a1.5421 1.5421 0 0 1-.0107.011c-.5946.6078-1.5693.6184-2.177.0237L25 26.976z" fill="#FFF" fill-rule="evenodd"/></svg>
                <span class="sr-only">Fermer</span>
            </button>

            <clip-loader
                v-if="$store.state.biens.sideShutterState === 'loading'"
                class="position: absolute inset-0 flex justify-center items-center"
                :color="spinnerColor"
                size="100px"
            />
            <div
                v-show="$store.state.biens.sideShutterState !== 'loading'"
                ref="scrollContainer"
                class="overflow-y-scroll absolute inset-0"
            >
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import tailwindConfig from "@/../tailwind.config.theme";
import { TweenLite, ScrollToPlugin, Power1 } from "gsap/all";

// eslint-disable-next-line no-unused-vars
const gsapPlugins = [ScrollToPlugin];

export default {
    name: "SideShutter",
    components: { ClipLoader },

    computed: {
        spinnerColor() {
            return tailwindConfig.colors.secondary;
        }
    },

    mounted() {
        document.body.style.overflow = "hidden";

        this.$store.commit(
            "biens/setSideShutterElement",
            this.$refs.scrollContainer
        );

        this.$store.watch(
            state => state.biens.selectedBien,
            () => {
                if (!this.$refs.scrollContainer) {
                    return;
                }

                TweenLite.to(this.$refs.scrollContainer, 1, {
                    scrollTo: 0,
                    ease: Power1.easeBackInOut
                });
            }
        );
    },

    destroyed() {
        document.body.style.overflow = "visible";

        this.$store.commit("biens/unsetSideShutterElement");
    }
};
</script>

<style lang="scss" scoped>
.side-shutter {
    background: rgba(#1a1a1a, 0.6);

    &__close-button {
        @screen lg {
            transform: translateX(-100%);
        }
    }
}
</style>
