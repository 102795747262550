<template>
    <div class="bien-details-share-popin z-30">
        <div
            class="bien-details-share-popin__overlay absolute inset-0 bg-gray-900 opacity-60"
        />

        <div class="h-screen">
            <div class="w-full h-full p-4">
                <!-- eslint-disable-next-line -->
                <div class="relative w-full h-full lg:flex lg:items-start lg:justify-center">
                    <!-- eslint-disable-next-line -->
                    <button
                        class="bien-details-share-popin__close-button absolute top-0 right-0 lg:static lg:order-last lg:-ml-8 z-10 bg-secondary p-2"
                        @click="$store.dispatch('biens/closeSharePopin')"
                    >
                        <!-- eslint-disable-next-line -->
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path fill="#fff" d="M14.1965.703c.4005-.3843 1.0367-.371 1.4209.0296.3892.4058.379 1.0493-.023 1.4425l-5.983 5.8542 5.9832 5.8552c.371.363.4082.9392.1056 1.3449l-.0827.0976a1.0044 1.0044 0 01-.007.0073c-.3882.3967-1.0245.4036-1.4212.0154l-6.03-5.9004-6.0285 5.9004a1.009 1.009 0 01-.0036.0035c-.4042.3877-1.0404.3744-1.4246-.0262-.3892-.4058-.379-1.0493.023-1.4425l5.9827-5.8552L.7255 2.175C.3545 1.8121.3173 1.236.62.8302L.7026.7326a1.005 1.005 0 01.007-.0072C1.0978.3287 1.7341.3218 2.1308.7099l6.0285 5.8994 6.03-5.8994a1.0112 1.0112 0 01.0035-.0035z"/></svg>
                    </button>

                    <!-- eslint-disable-next-line -->
                    <div class="max-h-full overflow-scroll px-4 pt-10 lg:px-10 bg-white">
                        <!-- eslint-disable-next-line -->
                        <h3 class="mb-4 font-spacegrotesk-medium text-2xl leading-normal">
                            Partager le lien
                        </h3>

                        <div class="mb-12 lg:mb-8">
                            <div class="flex flex-col lg:flex-row lg:max-w-lg">
                                <input
                                    class="lg:w-full bg-gray-100 p-3 text-sm"
                                    :value="bien.url"
                                    @click="selectText"
                                    @focus="selectText"
                                />

                                <multiple-state-button
                                    v-clipboard:copy="bien.url"
                                    v-clipboard:success="handleCopySuccess"
                                    v-clipboard:error="handleCopyError"
                                    class="btn--sm mt-4 lg:mt-0 lg:ml-6"
                                    type="button"
                                    :state="copyButtonState"
                                >
                                    <template slot="idle">
                                        Copier
                                    </template>
                                    <template slot="pending">
                                        <!-- eslint-disable-next-line -->
                                        <pulse-loader :loading="true" color="white" />
                                    </template>
                                    <template slot="success">
                                        Copié
                                    </template>
                                    <template slot="error">
                                        Erreur
                                    </template>
                                </multiple-state-button>
                            </div>
                        </div>

                        <!-- eslint-disable-next-line -->
                        <h3 class="mb-4 font-spacegrotesk-medium text-2xl leading-normal">
                            Envoyer l'annonce par mail
                        </h3>

                        <form
                            action=""
                            class="flex flex-col lg:flex-row lg:items-end lg:mb-10"
                            @submit.prevent="handleFormSubmit"
                        >
                            <div class="flex flex-col lg:flex-row mb-4 lg:mb-0">
                                <div class="flex flex-col mb-6 lg:mb-0">
                                    <label for="sender-email" class="mb-2">
                                        Email expéditeur
                                    </label>
                                    <input
                                        id="sender-email"
                                        v-model="form.fields.fromEmail"
                                        class="placeholder-gray-500 border border-gray-400 rounded p-2"
                                        type="email"
                                        required
                                    />
                                </div>

                                <div class="flex flex-col lg:ml-6">
                                    <label for="recipient-email" class="mb-2">
                                        Email destinataire
                                    </label>
                                    <input
                                        id="recipient-email"
                                        v-model="form.fields.toEmail"
                                        class="placeholder-gray-500 border border-gray-400 rounded p-2"
                                        type="email"
                                        required
                                    />
                                </div>
                            </div>

                            <!-- eslint-disable-next-line -->
                            <multiple-state-button
                                class="btn--sm mb-10 lg:mb-0 lg:ml-8"
                                type="submit"
                                :state="sendButtonState"
                            >
                                <template slot="idle">
                                    Envoyer
                                </template>
                                <template slot="pending">
                                    <!-- eslint-disable-next-line -->
                                    <pulse-loader :loading="true" color="white" size="6px" />
                                </template>
                                <template slot="success">
                                    Envoyé
                                </template>
                                <template slot="error">
                                    Erreur
                                </template>
                            </multiple-state-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import MultipleStateButton from "@/app/components/MultipleStateButton";

const clearFormsSuccessStatusTimeout = 3000;

export default {
    name: "BienDetailsSharePopin",

    components: { MultipleStateButton, PulseLoader },

    props: {
        bien: { type: Object, required: true }
    },

    data() {
        return {
            hasCopySucceeded: null,
            hasBeenSuccessfullySent: false,
            pendingText: '<i class="fa fa-shopping-cart">',
            isShareFormSubmitting: false,
            form: {
                fields: {
                    fromEmail: "",
                    toEmail: ""
                },
                validationErrors: {}
            },
            httpClient: axios.create({
                headers: {
                    "X-Custom-Header": window.Craft.csrfTokenValue
                }
            })
        };
    },

    computed: {
        sendButtonState() {
            if (this.isShareFormSubmitting) {
                return "pending";
            }

            if (
                this.form.validationErrors.form ||
                this.form.validationErrors.fromEmail ||
                this.form.validationErrors.toEmail ||
                this.form.validationErrors.message ||
                this.form.validationErrors.lastName
            ) {
                return "error";
            }

            if (this.hasBeenSuccessfullySent) {
                return "success";
            }

            return "idle";
        },

        copyButtonState() {
            if (this.hasCopySucceeded === true) {
                return "success";
            }

            if (this.hasCopySucceeded === false) {
                return "error";
            }

            return "idle";
        }
    },

    watch: {
        hasCopySucceeded() {
            setTimeout(() => {
                this.hasCopySucceeded = null;
            }, clearFormsSuccessStatusTimeout);
        }
    },

    mounted() {
        document.body.style.overflow = "hidden";
    },

    destroyed() {
        document.body.style.overflow = "visible";
    },

    methods: {
        selectText(event) {
            const input = event.currentTarget;
            input.focus();
            input.select();
        },

        handleCopySuccess() {
            this.hasCopySucceeded = true;
        },

        handleCopyError() {
            this.hasCopySucceeded = false;
        },

        handleFormSubmit() {
            // TODO: Securize the form: recaptcha, hashed form identifier
            this.isShareFormSubmitting = true;
            this.$set(this.form, "validationErrors", {});

            // Assemble the payload
            const payload = new URLSearchParams();
            payload.append("action", "contact-form/send");
            payload.append("formType", "share");
            payload.append("fromEmail", this.form.fields.fromEmail);
            payload.append("toEmail", this.form.fields.toEmail);
            payload.append("message[formName]", `Partage d'un bien`);
            payload.append("bienId", this.bien.id);
            payload.append(
                window.Craft.csrfTokenName,
                window.Craft.csrfTokenValue
            );

            // POST the payload
            this.httpClient
                .post(document.location.href, payload, {
                    "Content-Type": "application/x-www-form-urlencoded"
                })
                .then(response => {
                    if (response.data.success) {
                        this.hasBeenSuccessfullySent = true;

                        setTimeout(() => {
                            this.hasBeenSuccessfullySent = false;
                        }, clearFormsSuccessStatusTimeout);
                        return;
                    }

                    if (response.data.errors) {
                        this.form.validationErrors = response.data.errors;
                    }
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error(error);

                    if (
                        error.response &&
                        error.response.data &&
                        error.response.data.error
                    ) {
                        this.$set(
                            this.form.validationErrors,
                            "form",
                            error.response.data.error
                        );
                        return;
                    }

                    this.$set(
                        this.form.validationErrors,
                        "form",
                        "Une erreur s'est produite lors de l'envoi de votre message. Veuillez réessayer ultérieurement."
                    );
                })
                .finally(() => {
                    this.isShareFormSubmitting = false;
                });
        }
    }
};
</script>

<style scoped></style>
