<template>
    <section class="image-mosaic relative">
        <div class="image-mosaic__background absolute inset-0 md:w-2/3 bg-gray-100 min-h-300px" :class="textPosition === 'last' ? 'md:right-0 md:left-auto' : 'md:left-0 md:right-auto'"></div>

        <div class="md:container md:mx-auto mb-4 md:mb-30">
            <div class="grid-container relative">
                <div class="grid-row">
                    <div class="hidden md:block grid-col w-5/12" v-if="textPosition === 'last'"></div>
                    <div class="grid-col md:w-1/2 mt-8 mb-4 md:my-20">
                        <h2 v-if="heading" class="font-spacegrotesk-bold text-4xl lg:text5xl text-gray-700 mb-4">{{ heading }}</h2>
                        <slot name="text"></slot>
                    </div>
                </div>
            </div>

            <papillon class="papillon">
                <template #link="{ isOpened }">
                    <div class="text-secondary text-xl font-bold px-container flex items-center justify-start md:hidden">
                        Voir les réalisations
                        <svg class="arrow ml-2"
                             :class="{ 'arrow--active': isOpened }"
                             width="20"
                             height="20"
                             xmlns="http://www.w3.org/2000/svg"
                        ><g fill="none" fill-rule="evenodd"><path fill="#F2F2F2" d="M-202-221h375V63h-375z"/><g transform="rotate(90 10 10)"><circle fill="#45B7B7" cx="10" cy="10" r="10"/><path d="M8.364 6.431a.625.625 0 01.825-.048l.059.052 3.507 3.538-3.561 3.592a.625.625 0 01-.94-.82l.052-.06 2.688-2.713L8.36 7.316a.625.625 0 01-.048-.825l.052-.059z" fill="#FFF" fill-rule="nonzero"/></g></g></svg>
                    </div>
                </template>
                <template #content>
                    <div class="grid-container grid-container--no-gutter">
                        <div class="grid-row flex-wrap">
                            <figure v-for="(image, key) in images"
                                    :key="key"
                                    class="grid-col relative w-full"
                            >
                                <img :src="image.url" :alt="image.legend" width="400" height="300" class="w-full" />
                                <figcaption class="flex items-end absolute bottom-0 inset-x-0 h-1/2 text-white p-4">{{ image.legend }}</figcaption>
                            </figure>
                        </div>
                    </div>
                </template>
            </papillon>
        </div>
    </section>
</template>

<script>
import Papillon from '@/app/components/Papillon';

export default {
    name: 'ImageMosaic',

    components: { Papillon },

    props: {
        heading:      { type: String, default: null },
        textPosition: { type: String, default: null },
        images:       { type: Array, default: () => [] },
    },
}
</script>

<style lang="scss" scoped>
.image-mosaic {
    &__background {
        bottom: 30%;
    }
}

figure {
    @media (min-width: 500px) {
        @apply w-1/2;
    }

    @screen md {
        @apply w-1/3;
    }

    @screen lg {
        @apply w-1/4;
    }

    figcaption {
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

.papillon::v-deep {
    a {
        @apply relative;
    }

    @screen md {
        .overflow-hidden {
            display: block !important;
            height: auto !important;
        }
    }
}

.arrow {
    transition: transform 500ms ease-out;

    &--active {
        transform: rotate(-180deg);
    }
}
</style>
