<template>
    <div class="bien-list">
        <div ref="biens" class="flex flex-wrap -mx-4">
            <div
                v-for="(bien, key) in displayedResults"
                :key="key"
                class="w-full md:w-1/2 xl:w-1/3 px-4 mb-7"
            >
                <bien-list-item :bien="bien"></bien-list-item>
            </div>
        </div>

        <paginate
            :page-count="pageCount"
            :click-handler="pageChange"
            :page-range="3"
            :margin-pages="1"
            prev-text='<i class="fa fa-angle-left"></i>'
            next-text='<i class="fa fa-angle-right"></i>'
            break-view-text="…"
            container-class="max-w-md mx-auto flex justify-between items-center mt-1 pb-16 md:mt-12 md:pb-24"
            page-link-class="bien-list__pagination-button"
            prev-link-class="bien-list__pagination-button bien-list__pagination-button--prev"
            next-link-class="bien-list__pagination-button bien-list__pagination-button--next"
            break-view-link-class="bien-list__pagination-button bien-list__pagination-button--ellipsis"
            active-class="bien-list__pagination-button--active"
            disabled-class="bien-list__pagination-button--disabled"
            :no-li-surround="true"
        />

        <side-shutter
            v-if="
                $store.state.biens.isSideShutterOpen &&
                    $store.state.biens.displayMode === 'bien-list'
            "
            ref="sideShutter"
        >
            <bien-details v-if="selectedBien" :bien="selectedBien" />
        </side-shutter>
    </div>
</template>

<script>
import BienListItem from "./BienListItem";
import SideShutter from "../SideShutter";
import BienDetails from "./BienDetails";
import Paginate from "vuejs-paginate";
import { TimelineLite, CSSPlugin, ScrollToPlugin } from "gsap/all";

// eslint-disable-next-line no-unused-vars
const gsapPlugins = [CSSPlugin, ScrollToPlugin];

export default {
    name: "BienList",
    components: {
        BienListItem,
        SideShutter,
        BienDetails,
        Paginate
    },

    props: {
        results: {
            type: Array,
            default: null
        }
    },

    data() {
        return {
            itemsPerPage: 6,
            currentPage: 0
        };
    },

    computed: {
        pageCount() {
            return Math.ceil(this.results.length / this.itemsPerPage);
        },

        selectedBien() {
            const selectedBien = this.$store.state.biens.selectedBien;
            if (selectedBien && selectedBien.slug) {
                window.history.pushState({}, "", selectedBien.url);
            } else {
                if (window.bienListUrl) {
                    window.history.pushState({}, "", window.bienListUrl);
                }
            }

            return selectedBien;
        },

        displayedResults() {
            const array = [];

            const itemOffset = this.itemsPerPage * (this.currentPage + 1);
            let i = itemOffset - this.itemsPerPage;
            for (i; i < itemOffset; i++) {
                if (this.results[i]) {
                    array.push(this.results[i]);
                }
            }
            return array;
        }
    },

    mounted() {
        this.$store.watch(
            state => state.biens.selectedBien,
            () => {
                if (!this.$store.state.biens.selectedBien) {
                    window.history.pushState({}, "", window.bienListUrl);
                }
            }
        );
    },

    methods: {
        pageChange(page) {
            const timeline = new TimelineLite();
            timeline.to(window, 0.5, { scrollTo: this.$el });
            timeline.to(this.$refs.biens, 0.1, { opacity: 0 });
            timeline.set(this, { currentPage: page - 1 });
            timeline.to(this.$refs.biens, 0.75, { opacity: 1 });
        }
    }
};
</script>

<style lang="scss">
.bien-list {
    &__pagination-button {
        $self: &;

        @apply font-spacegrotesk-regular px-2 py-4;

        &:hover,
        &:focus,
        &:active {
            @apply no-underline;
        }

        &--active {
            @apply font-spacegrotesk-bold text-secondary;
        }

        &--ellipsis {
            cursor: default !important;
        }

        &--prev,
        &--next {
            @apply flex justify-center items-center rounded-full bg-secondary text-white;
            width: 2rem;
            height: 2rem;

            &#{ $self }--disabled {
                @apply opacity-50 cursor-not-allowed;
            }
        }
    }
}
</style>
