import biensApi from "@/app/api/biens";
import { TweenLite, ScrollToPlugin, Power1 } from "gsap/all";
import Vue from "vue";

// eslint-disable-next-line no-unused-vars
const gsapPlugins = [ScrollToPlugin];

export default {
    namespaced: true,

    state: {
        displayMode: "bien-list",
        selectedBien: null,
        sideShutterElement: null,
        isSideShutterOpen: false,
        sideShutterState: "idle",
        isSharePopinOpen: false
    },

    getters: {},

    actions: {
        showOnMap({ commit }, bien) {
            commit("setDisplayMode", "bien-map");
            commit("setSelected", bien);
        },

        async openSideShutter({ commit, state }, { bienSlug, $event }) {
            commit("openSideShutter");
            commit("setSideShutterLoadingStateLoading");

            /*
             * Wait! What?
             * The `commit("openSideShutter")` statement will trigger the side shutter addition to the DOM.
             * To check if the sideshutter is present, we have to wait for the DOM to be updated.
             * The `await Vue.nextTick()` statement pauses execution until the DOM has been patched.
             */
            await Vue.nextTick();

            // If side shutter isn't defined, this is a share LP. Trigger a regular navigation.
            if (!state.sideShutterElement) {
                document.location = $event.target.href;
                return;
            }

            const bien = await biensApi.find(bienSlug);
            if (bien) {
                commit("setSideShutterLoadingStateIdle");
                commit("setSelected", bien);
                return;
            }

            commit("setSideShutterLoadingStateError");
        },

        closeSideShutter({ commit }) {
            commit("closeSideShutter");
            commit("unselect");
            commit("setSideShutterLoadingStateIdle");
        },

        scrollSideShutter({ state }, targetId) {
            const container = state.sideShutterElement || window;

            TweenLite.to(container, 0.5, {
                scrollTo: targetId,
                ease: Power1.easeBackInOut
            });
        },

        openSharePopin({ commit }) {
            commit("openSharePopin");
        },

        closeSharePopin({ commit }) {
            commit("closeSharePopin");
        }
    },

    mutations: {
        setDisplayMode(state, displayMode) {
            if (displayMode === "bien-list") {
                state.selectedBien = null;
            }

            state.displayMode = displayMode;
        },

        setSelected(state, bien) {
            state.selectedBien = bien;
        },

        unselect(state) {
            state.selectedBien = null;
        },

        setSideShutterElement(state, sideShutterElement) {
            state.sideShutterElement = sideShutterElement;
        },

        unsetSideShutterElement(state) {
            state.sideShutterElement = null;
        },

        setSideShutterLoadingStateIdle(state) {
            state.sideShutterState = "idle";
        },

        setSideShutterLoadingStateError(state) {
            state.sideShutterState = "error";
        },

        setSideShutterLoadingStateLoading(state) {
            state.sideShutterState = "loading";
        },

        openSideShutter(state) {
            state.isSideShutterOpen = true;
        },

        closeSideShutter(state) {
            state.isSideShutterOpen = false;
        },

        openSharePopin(state) {
            state.isSharePopinOpen = true;
        },

        closeSharePopin(state) {
            state.isSharePopinOpen = false;
        }
    }
};
