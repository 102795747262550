<template>
    <div>
        <h4
            class="text-2xl font-spacegrotesk-medium"
            v-text="'Les diagnostics énergétiques'"
        />
        <div class="flex flex-col md:flex-row">
            <div class="w-full md:w-1/2 max-w-sm mr-20 flex flex-col">
                <p class="mt-3 font-spacegrotesk-regular text-base">
                    Diagnostic de performance énergétique
                </p>

                <bien-dpe
                    v-if="bien.biensDPEClassificationConso"
                    :label="bien.biensDPEClassificationConso"
                    :value="bien.biensDPEValeurConso"
                    :colors="tailwindColors.dpeConso"
                />
                <p
                    v-else
                    class="font-spacegrotesk-regular text-base mt-auto pb-1"
                    v-text="'Diagnostic indisponible'"
                />
            </div>

            <div class="w-full md:w-1/2 max-w-sm flex flex-col">
                <p class="mt-3 font-spacegrotesk-regular text-base">
                    Indice d'émission de gaz à effet de serre
                </p>

                <bien-dpe
                    v-if="bien.biensDPEClassificationGES"
                    :label="bien.biensDPEClassificationGES"
                    :value="bien.biensDPEValeurGES"
                    :colors="tailwindColors.dpeGES"
                />
                <p
                    v-else
                    class="font-spacegrotesk-regular text-base my-auto pb-1"
                    v-text="'Diagnostic indisponible'"
                />
            </div>
        </div>
        <div class="mt-12">
            <div class="w-full flex flex-col">
                <h4 class="text-2xl font-spacegrotesk-medium">
                    État des risques et pollutions
                </h4>
                <p class="mt-3 font-spacegrotesk-regular text-base">Les informations sur les risques auxquels ce bien est exposé sont disponibles sur le site Géorisques : <a class="mt-4 lg:font-spacegrotesk-bold text-sm lg:text-xl underline" href="https://georisques.gouv.fr">www.georisques.gouv.fr</a></p>
            </div>
        </div>
    </div>
</template>

<script>
import tailwindTheme from "@/../tailwind.config.theme";
import BienDpe from "@/app/components/biens/BienDpe";

export default {
    name: "BienDetailsDpe",
    components: { BienDpe },

    props: {
        bien: { type: Object, required: true }
    },

    computed: {
        tailwindColors: () => tailwindTheme.colors
    }
};
</script>
