<template>
    <div class="bien-details-map">
        <h4
            v-if="bien.biensGpsCoordinates.lat && bien.biensGpsCoordinates.lng"
            class="text-2xl font-spacegrotesk-medium"
            v-text="'Adresse'"
        />
        <gmap-map
            v-if="bien.biensGpsCoordinates.lat && bien.biensGpsCoordinates.lng"
            ref="map"
            :center="getLatLng(bien)"
            :zoom="16"
            class="bien-details-map__map mt-8"
            map-type-id="roadmap"
        >
            <gmap-marker
                ref="markers"
                :position="getLatLng(bien)"
                :clickable="false"
                :draggable="false"
                :icon="{
                    url: require('@/static/images/map-marker.png'),
                    scaledSize: { width: 66, height: 83 },
                    anchor: { x: 33, y: 72 }
                }"
            />
        </gmap-map>
    </div>
</template>

<script>
import GmapMap from "vue2-google-maps/dist/components/map";
import GmapMarker from "vue2-google-maps/dist/components/marker";

export default {
    name: "BienDetailsMap",

    components: { GmapMap, GmapMarker },

    props: {
        bien: { type: Object, required: true }
    },

    mounted() {
        if (
            !this.bien.biensGpsCoordinates.lat ||
            !this.bien.biensGpsCoordinates.lng
        ) {
            // eslint-disable-next-line no-console
            console.warn(
                `Pas de coordonnées GPS pour le bien "${
                    this.bien.heading
                }" => pas d'affichage de la carte.`
            );
        }
    },

    methods: {
        getLatLng(bien) {
            return {
                lat: parseFloat(bien.biensGpsCoordinates.lat),
                lng: parseFloat(bien.biensGpsCoordinates.lng)
            };
        }
    }
};
</script>

<style scoped lang="scss">
.bien-details-map {
    &__map {
        height: 23rem;
    }
}
</style>
