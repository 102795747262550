<template>
    <section
        id="resultats"
        class="bien-results relative bg-gray-100"
        :class="{ 'bien-results--map': activeDisplayMode === 'bien-map' }"
    >
        <div
            v-if="displayModes.length > 1"
            class="bien-results__toggle-switch toggle-switch pt-20 pb-10"
        >
            <div class="container mx-auto px-container lg:px-container-lg">
                <toggle
                    v-model="activeDisplayMode"
                    :alternatives="displayModes"
                    name="view-mode"
                />
            </div>
        </div>

        <bien-list
            v-if="activeDisplayMode === 'bien-list'"
            class="container mx-auto px-container lg:px-container-lg"
            :results="results"
        />
        <bien-map v-else class="h-screen" :results="results" />
    </section>
</template>

<script>
import BienList from "@/app/components/biens/BienList";
import BienMap from "@/app/components/biens/BienMap";
import Toggle from "@/app/components/search-form/Toggle";
import { TweenLite, ScrollToPlugin, Power1 } from "gsap/all";

// eslint-disable-next-line no-unused-vars
const gsapPlugins = [ScrollToPlugin];

export default {
    name: "BienResults",

    components: {
        BienList,
        BienMap,
        Toggle
    },

    props: {
        results: {
            type: Array,
            default: null
        }
    },

    computed: {
        displayModes() {
            const displayModes = [
                {
                    title: "Liste",
                    id: "bien-list",
                    // eslint-disable-next-line
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="21"><path fill-rule="evenodd" d="M1.5 18h21a1.5 1.5 0 0 1 0 3h-21a1.5 1.5 0 0 1 0-3zm0-18h21a1.5 1.5 0 0 1 0 3h-21a1.5 1.5 0 0 1 0-3zm0 9h21a1.5 1.5 0 0 1 0 3h-21a1.5 1.5 0 0 1 0-3z"/></svg>'
                }
            ];

            const isMapDisplayModeEnabled = this.results.some(result => {
                if (
                    result.biensGpsCoordinates &&
                    result.biensGpsCoordinates.lat &&
                    result.biensGpsCoordinates.lng
                ) {
                    return true;
                }
            });

            if (isMapDisplayModeEnabled) {
                displayModes.push({
                    title: "Carte",
                    id: "bien-map",
                    // eslint-disable-next-line
                    icon: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="24"><path fill-rule="evenodd" d="M9 12.5a4 4 0 1 1 0-8.001 4 4 0 0 1 0 8zm0-2a2.001 2.001 0 0 0 0-4 2 2 0 0 0 0 4zm0 12.676a1.47 1.47 0 0 1-1.04-.43 36.926 36.926 0 0 1-1.37-1.465 44.997 44.997 0 0 1-2.68-3.296C1.466 14.657 0 11.602 0 9.01A8.922 8.922 0 0 1 8.99 0 8.922 8.922 0 0 1 18 9c0 2.602-1.466 5.657-3.91 8.985a44.997 44.997 0 0 1-2.68 3.296 36.926 36.926 0 0 1-1.37 1.464 1.47 1.47 0 0 1-1.04.43zm.917-3.226a43.023 43.023 0 0 0 2.56-3.149C14.689 13.791 16 11.058 16 8.991A6.922 6.922 0 0 0 8.99 2 6.922 6.922 0 0 0 2 9c0 2.058 1.312 4.791 3.522 7.801a43.023 43.023 0 0 0 2.561 3.149c.351.393.663.73.917.996.254-.266.566-.603.917-.996z"/></svg>'
                });
            }

            return displayModes;
        },

        activeDisplayMode: {
            get() {
                return this.$store.state.biens.displayMode;
            },
            set(displayMode) {
                this.$store.commit("biens/setDisplayMode", displayMode);
            }
        }
    },

    watch: {
        activeDisplayMode() {
            TweenLite.to(window, 0.75, {
                scrollTo: { y: this.$el },
                ease: Power1.backEaseInOut
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.bien-results {
    &__toggle-switch {
        @screen md {
            left: calc(50% - theme("screens.md") / 2);
        }

        @screen lg {
            left: calc(50% - theme("screens.lg") / 2);
        }
        @screen xl {
            left: calc(50% - theme("screens.xl") / 2);
        }
    }
}
</style>
