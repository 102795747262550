<template>
    <div
        class="bien-dpe flex pt-3"
        :class="{
            'pb-3': !value,
            'pb-10': value
        }"
    >
        <div
            v-for="(color, key, index) in colors"
            :key="key"
            :title="key"
            :style="{ 'background-color': color }"
            class="bien-dpe__item w-full "
            :class="{ 'bien-dpe__item--selected': key === label }"
        >
            <div class="aspect-ratio-square relative">
                <div
                    class="absolute inset-0 m-auto flex justify-center items-center font-spacegrotesk-medium text-white text-2xl"
                    :class="{
                        'text-gray-700': !hasEnoughContrastWithWhiteText(color)
                    }"
                    v-text="isBound(index) || key === label ? key : null"
                />

                <div
                    v-if="key === label && value"
                    class="bien-dpe__value absolute inset-x-0 bottom-0 font-spacegrotesk-medium text-gray-700 text-center mt-4"
                    v-text="value"
                />
            </div>
        </div>
    </div>
</template>

<script>
import getContrastRatio from "get-contrast-ratio";

export default {
    name: "BienDpe",
    props: {
        colors: { type: Object, required: true },
        label: { type: String, required: true },
        value: { type: String, default: null }
    },

    methods: {
        isBound(index) {
            return (
                index === 0 || index === Object.values(this.colors).length - 1
            );
        },

        hasEnoughContrastWithWhiteText(color) {
            return getContrastRatio(color, "white") > 1.5;
        }
    }
};
</script>

<style scoped lang="scss">
.bien-dpe {
    &__item {
        &--selected {
            transform: scale(1.4);

            border: 2px solid theme("colors.white");
            border-radius: 50%;
        }
    }

    &__value {
        transform: translateY(1.5rem);
    }
}
</style>
