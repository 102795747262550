<template>
    <article v-if="bien" class="bien-details relative h-full">
        <bien-details-mobile-header class="lg:hidden" :bien="bien" />
        <bien-details-photos
            v-if="bien.biensPhotos && bien.biensPhotos.length > 0"
            class="bien-details__photos"
            :bien="bien"
        />

        <div class="mt-8 px-4 md:px-6 lg:px-11 pb-36 lg:pb-10">
            <bien-details-description
                :bien="bien"
                :contact-form-id="uid('contact-form')"
            />
            <bien-details-caracteristiques class="mt-19" :bien="bien" />
            <bien-details-dpe class="mt-12" :bien="bien" />
            <bien-details-map class="mt-12" :bien="bien" />
            <bien-details-contact-form
                :id="uid('contact-form')"
                class="mt-12"
                :bien="bien"
            />
            <bien-details-nearby-properties class="mt-12" :bien="bien" />
        </div>

        <bien-details-share-popin
            v-if="$store.state.biens.isSharePopinOpen === true"
            :bien="bien"
            class="fixed inset-0"
        />
    </article>
</template>

<script>
import BienItem from "@/app/mixins/bienItem";
import BienDetailsMobileHeader from "@/app/components/biens/BienDetailsMobileHeader";
import BienDetailsPhotos from "@/app/components/biens/BienDetailsPhotos";
import BienDetailsDescription from "@/app/components/biens/BienDetailsDescription";
import BienDetailsCaracteristiques from "@/app/components/biens/BienDetailsCaracteristiques";
import BienDetailsDpe from "@/app/components/biens/BienDetailsDpe";
import BienDetailsMap from "@/app/components/biens/BienDetailsMap";
import BienDetailsContactForm from "@/app/components/biens/BienDetailsContactForm";
import BienDetailsNearbyProperties from "@/app/components/biens/BienDetailsNearbyProperties";
import BienDetailsSharePopin from "@/app/components/biens/BienDetailsSharePopin";
import uid from "@/app/mixins/uid";

export default {
    name: "BienDetails",
    components: {
        BienDetailsMobileHeader,
        BienDetailsPhotos,
        BienDetailsDescription,
        BienDetailsCaracteristiques,
        BienDetailsDpe,
        BienDetailsMap,
        BienDetailsContactForm,
        BienDetailsNearbyProperties,
        BienDetailsSharePopin
    },

    mixins: [BienItem, uid]
};
</script>

<style lang="scss">
@use "sass:math";

.bien-details {
    &__photos {
        margin-top: math.div(-134px, 16px) * 1rem;
        @screen lg {
            margin-top: 0;
        }
    }
}
</style>
