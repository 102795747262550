<template>
    <div class="bien-list-item bg-white flex flex-col h-full rounded">
        <!-- prettier-ignore -->
        <a
            :href="bien.url"
            class="bien-list-item__header h-56 rounded-t"
            :style="'background-image: url(' + firstPhotoUrl + ');'"
            @click.prevent="$store.dispatch('biens/openSideShutter', { bienSlug: bien.slug, $event })"
        />
        <div class="bien-list-item__desc flex flex-col px-6 pt-2 pb-4">
            <h5 class="mb-2">{{ bien.heading }}</h5>
            <span class="bien-list-item__city text-gray-500">
                {{ bien.biensVille }} ({{ bien.biensCodePostal }})
            </span>
            <a
                v-if="shouldDisplayShowOnMapLink(bien)"
                href="#"
                class="inline-flex items-center mt-3"
                @click.prevent="showOnMap(bien)"
            >
                <!-- eslint-disable-next-line -->
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24"><path fill="#000" fill-rule="evenodd" d="M9 12.5a4 4 0 1 1 0-8.001 4 4 0 0 1 0 8zm0-2a2.001 2.001 0 0 0 0-4 2 2 0 0 0 0 4zm0 12.676a1.47 1.47 0 0 1-1.04-.43 36.926 36.926 0 0 1-1.37-1.465 44.997 44.997 0 0 1-2.68-3.296C1.466 14.657 0 11.602 0 9.01A8.922 8.922 0 0 1 8.99 0 8.922 8.922 0 0 1 18 9c0 2.602-1.466 5.657-3.91 8.985a44.997 44.997 0 0 1-2.68 3.296 36.926 36.926 0 0 1-1.37 1.464 1.47 1.47 0 0 1-1.04.43zm.917-3.226a43.023 43.023 0 0 0 2.56-3.149C14.689 13.791 16 11.058 16 8.991A6.922 6.922 0 0 0 8.99 2 6.922 6.922 0 0 0 2 9c0 2.058 1.312 4.791 3.522 7.801a43.023 43.023 0 0 0 2.561 3.149c.351.393.663.73.917.996.254-.266.566-.603.917-.996z"/></svg>
                <span class="text-secondary font-spacegrotesk-bold ml-2">
                    Situer sur la carte
                </span>
            </a>
        </div>
        <div v-if="!hideSpecs" class="bien-list-item__specs flex items-stretch">
            <div
                class="bien-list-item__spec flex flex-col items-center justify-end py-4 px-2 w-full md:w-1/2 lg:w-1/3"
            >
                <!-- eslint-disable-next-line -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25"><g fill="#000" fill-rule="nonzero" stroke="#000" stroke-width=".5"><path d="M23.066 1H8.296a.615.615 0 0 0-.615.615v14.77c0 .34.276.615.616.615h14.769c.34 0 .615-.275.615-.615V1.615A.615.615 0 0 0 23.066 1zm-.615 14.77H8.912V2.23h13.539v13.54zM1.427 14.103a.727.727 0 0 0-.959 0 .576.576 0 0 0 0 .87l2.034 1.847c.133.12.306.18.48.18.173 0 .347-.06.48-.18l2.034-1.846a.576.576 0 0 0 0-.87.728.728 0 0 0-.96 0l-.876.795V3.101l.877.796c.132.12.305.18.48.18.173 0 .346-.06.479-.18a.576.576 0 0 0 0-.87L3.46 1.18a.727.727 0 0 0-.959 0L.468 3.027a.576.576 0 0 0 0 .87c.265.24.694.24.959 0l.877-.796V14.9l-.877-.796zM21.655 19.186a.576.576 0 0 0-.87 0 .728.728 0 0 0 0 .959l.796.876H9.782l.796-.876a.727.727 0 0 0 0-.96.576.576 0 0 0-.87 0L7.862 21.22a.727.727 0 0 0 0 .959l1.846 2.034c.12.133.278.2.435.2a.586.586 0 0 0 .435-.2.727.727 0 0 0 0-.958l-.796-.877h11.799l-.796.876a.727.727 0 0 0 0 .96c.12.132.277.198.435.198a.586.586 0 0 0 .435-.198l1.846-2.035a.727.727 0 0 0 0-.959l-1.846-2.034z"/></g></svg>
                <span class="bien-list-item__spec-text text-center mt-2">
                    {{ bien.biensSurface }} m²
                </span>
            </div>
            <div
                class="bien-list-item__spec flex flex-col items-center justify-end py-4 px-2 w-full md:w-1/2 lg:w-1/3"
            >
                <!-- eslint-disable-next-line -->
                <svg
                    v-if="bien.biensNombreChambres !== null"
                    width="24"
                    height="19"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <!-- eslint-disable-next-line -->
                    <path d="M20.07 2.009V0h1.923v7.03H24V19h-1.923v-4.018H1.923V19H0V7.031h2.007V0H3.93v2.009h16.14zm0 1.925h-7.108V7.03h7.108V3.934zm1.923 5.022H1.923v4.101h20.154V8.956h-.084zM3.93 3.934V7.03h7.108V3.934H3.93z" fill="#000" fill-rule="evenodd"/>
                </svg>
                <span
                    v-if="bien.biensNombreChambres !== null"
                    class="bien-list-item__spec-text text-center mt-2"
                >
                    <!-- eslint-disable-next-line -->
                    {{ bien.biensNombreChambres }} {{ bien.biensNombreChambres < 2 ? "chambre" : "chambres" }}
                </span>
            </div>
            <div
                class="bien-list-item__spec flex flex-col items-center justify-end py-4 px-2 w-full md:w-1/2 lg:w-1/3"
            >
                <svg
                    v-if="bien.biensEtage !== null"
                    width="25"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <!-- eslint-disable-next-line -->
                    <g fill="none" fill-rule="evenodd"><path d="M3.666 7.49L.55 10.3a.67.67 0 0 0 0 .994.822.822 0 0 0 1.1 0l1.787-1.611v8.38a.779.779 0 0 0 1.557 0v-8.38l1.786 1.611a.822.822 0 0 0 1.101 0 .67.67 0 0 0 0-.994L4.767 7.49a.84.84 0 0 0-1.101 0z" fill-rule="nonzero" fill="#111"/><path stroke="#111" stroke-width="2" stroke-linecap="square" d="M10.998 4.208l6.25-3.125 6.25 3.125v18.75h-12.5zM15.164 8.375v1.042M19.331 8.375v1.042M15.164 13.583v1.042M19.331 13.583v1.042"/><path fill="#111" fill-rule="nonzero" d="M15.432 17.75h4.167v5.208h-4.167z"/></g>
                </svg>
                <!-- eslint-disable-->
                <span
                    v-if="bien.biensEtage !== null"
                    class="bien-list-item__spec-text text-center mt-2"
                    v-html="etage"
                />
                <!-- eslint-enable-->
            </div>
        </div>
        <div class="bien-list-item__footer mt-4 mb-8 px-6 text-center">
            <div class="bien-list-item__price-wrap mb-4">
                <span
                    class="bien-list-item__price font-spacegrotesk-bold"
                    v-text="price"
                />
                <br />
                <span
                    class="bien-list-item__conditions text-gray-500 font-spacegrotesk-medium"
                    v-text="priceDetails"
                />
            </div>
            <!-- eslint-disable -->
            <a
                :href="bien.url"
                class="btn btn--outline-blue btn--sm"
                @click.prevent="$store.dispatch('biens/openSideShutter', { bienSlug: bien.slug, $event })"
            >
            <!-- eslint-enable -->
                Découvrir le bien
            </a>
        </div>
    </div>
</template>

<script>
import BienItemMixin from "../../mixins/bienItem";

export default {
    name: "BienListItem",

    mixins: [BienItemMixin],

    props: {
        hideShowOnMapLink: { type: Boolean, default: false },
        hideSpecs: { type: Boolean, default: false }
    },

    computed: {
        firstPhotoUrl() {
            if (!this.bien.biensPhotos || !this.bien.biensPhotos[0]) {
                return null;
            }

            return this.bien.biensPhotos[0].url;
        }
    },

    methods: {
        showOnMap(bien) {
            this.$store.dispatch("biens/showOnMap", bien);
        },

        shouldDisplayShowOnMapLink(bien) {
            if (this.hideShowOnMapLink) {
                return false;
            }

            return bien.biensGpsCoordinates.lat && bien.biensGpsCoordinates.lng;
        }
    }
};
</script>

<style lang="scss" scoped>
.bien-list-item {
    border-color: theme("colors.gray.200");
    border-width: 1px;
    border-style: solid;

    box-shadow: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.1);

    &__header {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &__specs {
        border-top-width: 1px;
        border-bottom-width: 1px;
        border-color: theme("colors.gray.200");
        border-style: solid;
    }

    &__spec {
        border-right-width: 1px;
        border-color: theme("colors.gray.200");
        border-style: solid;

        &:last-child {
            border-right-width: 0;
        }
    }

    &__spec-text {
        font-size: 1rem;
        line-height: 1.375rem;
    }

    &__price {
        font-size: 2.125rem;
        line-height: 2.5rem;
    }
    &__conditions {
        font-size: 0.75rem;
        line-height: 1rem;
    }
}
</style>
