var render = function render(){var _vm=this,_c=_vm._self._c;return _c('gmap-map',{ref:"map",staticClass:"bien-map",attrs:{"center":{ lng: 5.724524, lat: 45.188529 },"zoom":10,"options":{
        mapTypeControl: false
    },"map-type-id":"roadmap"}},[_c('gmap-info-window',{attrs:{"options":{
            maxWidth: 320,
            pixelOffset: { width: 0, height: -70 }
        },"position":_vm.getLatLng(_vm.selectedBien),"opened":_vm.selectedBien !== null},on:{"closeclick":function($event){return _vm.toggleSelected(_vm.selectedBien)}}},[(_vm.selectedBien)?_c('bien-map-info-window',{attrs:{"bien":_vm.selectedBien}}):_vm._e()],1),_vm._l((_vm.resultsWithGpsCoordinates),function(bien){return _c('gmap-marker',{key:bien.id,ref:"markers",refInFor:true,attrs:{"position":_vm.getLatLng(bien),"clickable":true,"draggable":false,"icon":{
            url: _vm.getMarkerImageUrl(bien),
            scaledSize: { width: 66, height: 83 },
            anchor: { x: 33, y: 72 }
        },"z-index":_vm.getZindex(bien)},on:{"click":function($event){return _vm.toggleSelected(bien)}}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }