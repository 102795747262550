<template>
    <div v-if="hasCharacteristics">
        <h4 class="text-2xl font-spacegrotesk-medium">
            Plus d'informations sur le logement
        </h4>

        <!-- prettier-ignore -->
        <div class="md:col-count-2 md:col-gap-md xl:col-gap-lg mt-8 lg:mt-12">
            <bien-details-caracteristiques-item v-if="bien.biensSurface !== null">
                <template slot="label">Surface</template>
                <template slot="value">{{ bien.biensSurface }} m²</template>
            </bien-details-caracteristiques-item>

            <bien-details-caracteristiques-item v-if="bien.biensNombreChambres !== null">
                <template slot="label">Nombre de chambres</template>
                <template slot="value">{{ bien.biensNombreChambres }}</template>
            </bien-details-caracteristiques-item>

            <bien-details-caracteristiques-item v-if="bien.biensEtage !== null">
                <template slot="label">Étage</template>
                <template slot="value">
                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <span v-html="etage.replace(/ étage/, '')" />
                </template>
            </bien-details-caracteristiques-item>

            <bien-details-caracteristiques-item v-if="bien.biensAscenseur !== null">
                <template slot="label">Ascenseur</template>
                <template slot="value">{{ bien.biensAscenseur ? "Oui" : "Non" }}</template>
            </bien-details-caracteristiques-item>

            <bien-details-caracteristiques-item v-if="bien.biensBalcon !== null">
                <template slot="label">Balcon</template>
                <template slot="value">{{ bien.biensBalcon ? "Oui" : "Non" }}</template>
            </bien-details-caracteristiques-item>

            <bien-details-caracteristiques-item v-if="bien.biensChauffage !== null">
                <template slot="label">Chauffage</template>
                <template slot="value">{{ bien.biensChauffage | capitalize }}</template>
            </bien-details-caracteristiques-item>

            <bien-details-caracteristiques-item v-if="bien.biensTypeChauffage !== null">
                <template slot="label">Type de chauffage</template>
                <template slot="value">{{ bien.biensTypeChauffage | capitalize }}</template>
            </bien-details-caracteristiques-item>

            <bien-details-caracteristiques-item v-if="bien.biensEauChaude !== null">
                <template slot="label">Eau chaude</template>
                <template slot="value">{{ bien.biensEauChaude | capitalize }}</template>
            </bien-details-caracteristiques-item>
        </div>
    </div>
</template>

<script>
import bienItem from "@/app/mixins/bienItem";
import BienDetailsCaracteristiquesItem from "@/app/components/biens/BienDetailsCaracteristiquesItem";

export default {
    name: "BienDetailsCaracteristiques",

    components: { BienDetailsCaracteristiquesItem },

    filters: {
        capitalize: function(value) {
            if (!value) return "";
            value = value.toString();
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
    },

    mixins: [bienItem],

    props: {
        bien: { type: Object, required: true }
    },

    computed: {
        hasCharacteristics() {
            return (
                this.bien.biensSurface !== null ||
                this.bien.biensNombreChambres !== null ||
                this.bien.biensEtage !== null ||
                this.bien.biensAscenseur !== null ||
                this.bien.biensBalcon !== null ||
                this.bien.biensChauffage !== null ||
                this.bien.biensTypeChauffage !== null ||
                this.bien.biensEauChaude !== null
            );
        }
    }
};
</script>
