import axios from "axios";

const homeLink = document.querySelector("link[rel=home][href]");
const baseUrl = homeLink ? homeLink.href : "/";

export default {
    async find(slug) {
        try {
            const response = await axios.get(`${baseUrl}/biens/${slug}.json`);

            if (!response.data) {
                // eslint-disable-next-line no-console
                console.error("Unexpected response from API: ", response);
            }

            return response.data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }

        return null;
    }
};
