<template>
    <div class="flex flex-col lg:flex-row">
        <div class="w-full lg:w-2/3 text-gray-700">
            <h3
                class="hidden lg:block font-spacegrotesk-medium text-4xl "
                v-text="bien.heading"
            />

            <div class="text-xl text-gray-500 mb-8 lg:mb-10">
                Référence : {{ bien.biensReferencePluralis }}
            </div>

            <h4
                class="text-2xl font-spacegrotesk-medium"
                v-text="'Description'"
            />
            <p
                class="mt-3 font-spacegrotesk-regular text-base"
                v-text="bien.biensDescription"
            />
        </div>

        <hr class="lg:hidden mt-8 mb-8 lg:mb-0" />

        <div class="w-full lg:w-1/3 lg:ml-12 text-gray-700 text-center">
            <div class="text-4xl font-spacegrotesk-bold" v-text="price" />
            <div class="text-base lg:mt-1" v-text="priceDetails" />

            <a
                :href="`#${contactFormId}`"
                class="btn btn--blue fixed z-10 lg:static inset-x-0 bottom-0 mt-3"
                @click.prevent="onContactButtonClicked"
                v-text="'Nous contacter'"
            />

            <button
                class="mt-4 lg:font-spacegrotesk-bold text-sm lg:text-xl underline"
                type="button"
                @click="$store.dispatch('biens/openSharePopin')"
            >
                Partager cette annonce
            </button>
        </div>
    </div>
</template>

<script>
import BienItem from "@/app/mixins/bienItem";

export default {
    name: "BienDetailsDescription",
    mixins: [BienItem],

    props: {
        contactFormId: { type: String, required: true }
    },

    methods: {
        onContactButtonClicked(event) {
            const hash = event.currentTarget.attributes.href.value;
            this.$store.dispatch("biens/scrollSideShutter", hash);
        }
    }
};
</script>
