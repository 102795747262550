<template>
    <div class="flex border-b border-solid border-gray-200 py-3 bi-avoid">
        <span class="font-normal text-left text-gray-700">
            <slot name="label" />
        </span>
        <span class="ml-auto font-spacegrotesk-bold text-right">
            <slot name="value" />
        </span>
    </div>
</template>

<script>
export default {
    name: "BienDetailsCaracteristiquesItem"
};
</script>
